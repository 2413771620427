import { customTheme } from 'theme';

import { defaultSelectStyles } from '../Select';

import {
  ArrayFieldStyles,
  CheckboxFieldStyles,
  DayPickerFieldStyles,
  EmailIdsFieldStyles,
  FieldStyles,
  FormStyles,
  ObjectFieldStyles,
  RadioFieldStyles,
  SelectFieldStyles,
  SwitchFieldStyles,
  TextAreaFieldStyles,
  TextFieldStyles,
  TimePickerFieldStyles,
} from './types';

const fontSize = 'sm';

const labelStyles = {
  fontSize: fontSize,
  color: 'black',
  fontWeight: 600,
  //mr: 2,
};

const controlStyles = {
  //mb: 3,
};

export const commonFieldStyles = {
  //size: 'xs',
  fontSize: 'fontSize',
  h: 'full',
  borderRadius: 'fieldBorderRadius',
  py: '6px',
  px: 3,
  _hover: {
    borderWidth: '1px',
    borderColor: 'primary',
  },
  _focus: {
    boxShadow: 'none',
    borderColor: 'primary',
  },
  _active: {
    boxShadow: 'none',
    borderColor: 'primary',
  },
  borderColor: 'formFieldBorder',
  //focusBorderColor: 'primary',
};

export const arrayFieldStyles: ArrayFieldStyles = {
  arrayContainer: {
    spacing: 4,
    marginTop: 2,
  },
  label: {
    padding: 0,
    display: 'flex',
  },
  countText: {
    fontWeight: 'medium',
    marginLeft: 1,
  },
  toolbar: {
    alignItems: 'center',
  },
  buttonGroup: {
    marginLeft: 'auto',
  },
  addButton: {
    size: 'xs',
    variant: 'outline',
    color: 'primary',
    border: '1px',
    borderColor: 'primary',
    _hover: {
      bg: 'primary',
      color: 'white',
    },
    _focus: {},
  },
  deleteButton: {
    size: 'xs',
    margin: 'auto',
    color: customTheme.colors.red['500'],
    bg: 'inherit',
    _hover: {
      color: 'white',
      bg: customTheme.colors.red['500'],
    },
    _focus: {},
  },
  clearButton: {
    size: 'xs',
    variant: 'outline',
    colorScheme: 'red',
    _hover: {
      bg: customTheme.colors.red['500'],
      color: 'white',
    },
    _focus: {},
  },
  collapseButton: {
    size: 'xs',
  },
  itemContainer: {
    display: 'grid',
    w: 'full',
    gridTemplateColumns: '1fr 2.5rem',
    paddingLeft: 2,
    paddingBottom: 2,
    paddingTop: 1,
    border: '1px solid',
    borderRadius: 4,
    borderColor: customTheme.colors.gray['200'],
    backgroundColor: customTheme.colors.gray['50'],
  },
  deleteItemContainer: {
    display: 'flex',
    pt: 1,
  },
};

export const objectFieldStyles: ObjectFieldStyles = {
  objectContainer: {
    spacing: 4,
    borderWidth: 1,
    borderColor: customTheme.colors.gray['200'],
    paddingX: 4,
    paddingTop: 2,
    paddingBottom: 4,
    borderRadius: 4,
    backgroundColor: customTheme.colors.gray['50'],
  },
  control: controlStyles,
  label: labelStyles,
  toolbar: { alignItems: 'center' },
  collapseButton: {
    size: 'xs',
    marginLeft: 'auto',
  },
};

export const inlineObjectFieldStyles: ObjectFieldStyles = {
  objectContainer: {
    alignItems: 'center',
    padding: 0,
    direction: ['column', 'row'],
    border: 'none',
    bg: 'transparent',
  },
};

export const textFieldStyles: TextFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
  input: { ...commonFieldStyles },
  inputGroup: { size: 'sm' },
};

export const textAreaFieldStyles: TextAreaFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
  textArea: { ...commonFieldStyles },
};

export const switchFieldStyles: SwitchFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
  switch: {
    colorScheme: 'indigo',
  },
};

export const customFieldStyles: FieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
};

export const selectFieldStyles: SelectFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
  select: { ...commonFieldStyles, minH: '30px' },
};

export const multiSelectFieldStyles: SelectFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
  select: { ...commonFieldStyles },
};

export const checkboxFieldStyles: CheckboxFieldStyles = {
  checkbox: {
    size: 'sm',
    //colorScheme: 'indigo',
    _focus: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
    },
  },
  checkboxGroup: {
    isInline: true,
    spacing: 4,
  },
  label: { ...labelStyles },
  control: controlStyles,
};

export const radioFieldStyles: RadioFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
  radioGroupInnerWrapper: {
    isInline: true,
    spacing: 4,
  },
  radioGroup: {},
  radio: {
    size: 'sm',
    //colorScheme: 'indigo',
    _focus: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
    },
  },
};

export const reactSelectFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
  select: {
    ...defaultSelectStyles,
  },
  checkbox: {
    size: 'sm',
    //colorScheme: 'indigo',
    _focus: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
    },
  },
};

export const dayPickerFieldStyles: DayPickerFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
  dayPicker: {
    inputBox: { ...commonFieldStyles },
  },
};

export const timePickerFieldStyles: TimePickerFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
};

export const emailIdsFieldStyles: EmailIdsFieldStyles = {
  label: { ...labelStyles },
  control: controlStyles,
};

//padding: 4,
export const defaultStyles: FormStyles = {
  form: {
    formWidth: ['full', 'full', 'full', 'formWidth'],
    container: {
      w: 'full',
      fontSize: fontSize,
    },
    fieldSpacing: 8,
    buttonGroup: {
      mt: 10,
      flexDirection: 'row',
      width: '100%',
    },
    submitButtonGroup: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    resetButton: {
      size: 'sm',
      fontWeight: 'sm',
      _focus: {
        outline: 'none',
        boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
      },
    },
    backButton: {
      size: 'sm',
      fontWeight: 'sm',
    },
    cancelButton: {
      size: 'sm',
      fontWeight: 'sm',
    },
    containerWrapper: {
      w: ['full', 'full', 'full', '50%'],
      h: 'full',
    },
    helpWrapper: {
      w: ['full', 'full', 'full', '50%'],
      overflow: 'auto',
      pos: 'relative',
      h: 'full',
      //mr: 4,
      //my: 4,
    },
    helpComponent: {
      w: 'full',
      h: 'full',
      borderLeft: '1px solid',
      borderColor: customTheme.colors.gray['100'],
      pb: 4,
      px: 4,
      pt: 0,
      pos: 'absolute',
      left: 0,
      overflow: 'auto',
    },
  },
  textField: textFieldStyles,
  textAreaField: textAreaFieldStyles,
  objectField: objectFieldStyles,
  arrayField: arrayFieldStyles,
  checkboxField: checkboxFieldStyles,
  switchField: switchFieldStyles,
  selectField: selectFieldStyles,
  radioField: radioFieldStyles,
  multiSelectField: multiSelectFieldStyles,
  reactSelectField: reactSelectFieldStyles,
  customField: customFieldStyles,
  dayPickerField: dayPickerFieldStyles,
  timePickerField: timePickerFieldStyles,
  emailIds: emailIdsFieldStyles,
};
