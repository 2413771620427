/**
 *
 * SlackWizard
 *
 */

import React from 'react';

import { ClientsApiEditClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { CreateSlack } from 'containers/Setup/Integrations/Components/Slack/Components/CreateSlack';
import { EditSlack } from 'containers/Setup/Integrations/Components/Slack/Components/EditSlack';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions } from '../../../slice';

export const SlackWizard = () => {
  const { onCancel, actionType, currentRecord } = useIntegrationsContext();
  const dispatch = useDispatch();

  const onUpdate = data => {
    const { name } = data;
    const slack_channel_name = data?.slack_channel?.label;
    const slack_channel_id = data?.channel_id;
    const workspace = currentRecord?.slack?.workspace;

    const payload: ClientsApiEditClientRequest = {
      uuid: currentRecord?.uuid,
      editClientConfig: {
        name,
        status: true,
        slack: {
          channel_id: slack_channel_id,
          //channel_name: slack_channel_name,
          workspace,
        },
      },
    };
    dispatch(
      actions.updateClientById({ q: payload, onSuccess: () => onCancel() }),
    );
  };

  return actionType === 'Add' ? (
    <CreateSlack />
  ) : (
    <EditSlack onSubmit={onUpdate} />
  );
};
