import React from 'react';

import { Box, Flex, Stack, HStack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { H2 } from 'components/DataDisplay';
import { CloseIcon, CustomerJourneyBulbIcon } from 'components/Icons';

interface Props {
  onClose: () => void;
}

export const EnablingAccountForOutpostHelp = (props: Props) => {
  const styles = { pl: 4, color: customTheme.colors.gray['300'] };
  return (
    <Stack>
      <Flex w="full" justify="flex-end">
        <Box
          boxSize={6}
          p={1}
          color="primary"
          onClick={props.onClose}
          border="1px solid"
          borderColor={customTheme.colors.gray['200']}
          borderRadius={6}
          cursor="pointer"
        >
          <CloseIcon />
        </Box>
      </Flex>
      <Stack spacing={6} fontSize="md" pr={4}>
        <HStack>
          <Box boxSize={10}>
            <CustomerJourneyBulbIcon color="orange" />
          </Box>
          <H2>Enabling Account for Outpost</H2>
        </HStack>
        <Box {...styles}>
          Indicate the account you’d like to use as the central account for
          Outpost deployment. Outpost in the central account will automatically
          scan VMs, containers and serverless deployed in accounts enabled for
          it.
        </Box>
        <Box {...styles}>
          If you do not intend to centralize vulnerability scanning in an
          account, you do not need to enable this account for Outpost scanning.
        </Box>
        <Box {...styles}>
          Ariksa creates a cross-account role for the Outpost account to scan
          for vulnerabilities.
        </Box>
      </Stack>
    </Stack>
  );
};
