import React, { FC, useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Center, Stack, useDisclosure } from '@chakra-ui/react';
import { sum } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  CustomTooltip,
  renderTooltipFieldValue,
  StackedCell,
  timeLabel,
  timeTooltipLabel,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton } from 'components/DataEntry';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { ECRImageContainers } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ECR/Components/ECRImageContainers';
import { renderVulnerabilities } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { VulnerabilityDetails } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/VulnerabilityDetails';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { renderImageName } from 'containers/Visibility/Vulnerabilities/Components/hooks/utils';
import { ListIcon } from 'components/Icons';

interface Props {
  vulnerability?: boolean;
}

export const ECRImages: FC<Props> = props => {
  const { vulnerability = true } = props;
  const {
    resourceUuid,
    ecrContainerImages,
    searchTerm: finalSearchTerm,
  } = useSelector(selectActiveResource);
  const { environmentId } = useAccessBoundary();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const showContainers = useDisclosure();

  useEffect(() => {
    setSearchTerm(finalSearchTerm);
  }, [finalSearchTerm]);

  useEffect(() => {
    dispatch(
      actions.getECRContainerImages({
        q: {
          environmentId,
          page: ecrContainerImages.page.info.page_number,
          pageSize: ecrContainerImages.page.info.page_size,
          repositoryUuid: resourceUuid,
          searchTerm: !!finalSearchTerm ? finalSearchTerm : undefined,
        },
      }),
    );
  }, [
    environmentId,
    dispatch,
    ecrContainerImages.page.info,
    resourceUuid,
    finalSearchTerm,
  ]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      render: ({ row }) =>
        renderImageName(row, NativeResources.ElasticContainerRegistry),
    },
    {
      header: 'Registry',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={row?.registry_id}
          lower={row?.repository_arn ?? row?.repository_name}
        />
      ),
    },
    ...(!vulnerability
      ? [
          {
            header: 'Details',
            align: 'left',
            render: ({ row }) => (
              <StackedCell
                upper={row?.image_size_in_bytes + ' (Bytes)'}
                lower={row?.image_digest}
              />
            ),
          },
        ]
      : []),
    {
      header: 'Status',
      align: 'left',
      render: ({ row }) => {
        return vulnerability ? (
          <CustomTooltip
            label={
              <Stack>
                {renderTooltipFieldValue('Scan status', row?.image_scan_status)}
                {renderTooltipFieldValue(
                  'Pushed at',
                  timeLabel(row?.image_pushed_at),
                )}
              </Stack>
            }
          >
            <StackedCell
              upper={row?.image_scan_status}
              lower={timeLabel(row?.image_pushed_at)}
              showLowerTooltip={false}
              showUpperTooltip={false}
            />
          </CustomTooltip>
        ) : (
          row?.image_scan_status
        );
      },
    },
    ...(!vulnerability
      ? [
          {
            header: 'Activity',
            align: 'left',
            render: ({ row }) => (
              <StackedCell
                upper={timeLabel(row?.last_recorded_pull_time)}
                upperTooltip={timeTooltipLabel(row?.last_recorded_pull_time)}
                lower={timeLabel(row?.created_at)}
                lowerTooltip={timeTooltipLabel(row?.created_at)}
              />
            ),
          },
        ]
      : []),
    ...(!vulnerability
      ? [
          {
            header: 'Owner',
            align: 'left',
            accessor: 'owner',
          },
        ]
      : [
          {
            header: 'Summary',
            align: 'left',
            render: ({ row }) => {
              return renderVulnerabilities(row?.vulnerabilities, false);
            },
          },
        ]),
    {
      header: 'Actions',
      render: ({ row }) => (
        <Center>
          <ActionButton
            icon={<ListIcon />}
            label="Show containers"
            onClick={e => {
              e.stopPropagation();
              showContainers.onOpen();
              setCurrentRecord(row);
            }}
          />
        </Center>
      ),
    },
  ];

  return (
    <Box h="full" minH="400px">
      <Stack h="full" spacing={3}>
        <Box w="30%">
          <SearchBox
            value={searchTerm}
            onChange={s => {
              setSearchTerm(s);
              if (!s) dispatch(actions.updateSearchTerm(''));
            }}
            onSearch={() => dispatch(actions.updateSearchTerm(searchTerm))}
            onClickClose={() => dispatch(actions.updateSearchTerm(''))}
          />
        </Box>
        <Box flex={1}>
          <Table
            columns={columns}
            data={ecrContainerImages.data ?? []}
            isLoading={ecrContainerImages.isLoading}
            pagination={{
              pageInfo: ecrContainerImages.page.info,
              onChange: info =>
                dispatch(actions.updateECRImagesPagination(info)),
              totalCount: ecrContainerImages.page.totalCount,
            }}
            {...(vulnerability
              ? {
                  subComponent: ({ row }) => (
                    <VulnerabilityDetails
                      vulnerabilityType={'library'}
                      total={sum(Object.keys(row?.vulnerabilities))}
                      instanceId={row?.image_digest}
                    />
                  ),
                }
              : {})}
          />
        </Box>
        {showContainers.isOpen && (
          <ECRImageContainers
            isOpen={showContainers.isOpen}
            row={currentRecord}
            onClose={showContainers.onClose}
          />
        )}
      </Stack>
    </Box>
  );
};
