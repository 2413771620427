/**
 *
 * Slack Form
 *
 */

import React, { useEffect, useReducer } from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { Form, FormSchema, QuestionCircleIcon } from 'app/components';
import { GetChannelID } from 'containers/Setup/Integrations/Components/Slack/Components/Help/GetChannelID';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

import { formStyles } from '../../../styles';

import { CreateOAuthToken } from './Help/CreateOAuthToken';

interface Props {
  handleSubmit: (date: any) => void;
}

export const SlackForm = (props: Props) => {
  const { handleSubmit } = props;
  const { client } = useSelector(selectIntegrations);
  const { actionType, onCancel, currentRecord } = useIntegrationsContext();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      name: '',
      channel: '',
      validate: false,
      isEdit: false,
      tokenHelp: false,
      channelHelp: false,
    },
  );

  //set isEdit true if opened in edit
  useEffect(() => {
    updateState({ isEdit: actionType === 'Update' });
  }, [actionType]);

  //set selected channel in update mode
  useEffect(() => {
    if (state.isEdit) {
      updateState({
        channel: currentRecord?.slack?.channel_id,
        name: currentRecord?.name,
        validate: state.isEdit,
      });
    }
  }, [currentRecord, state.isEdit]);

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ ]*$/i,
        'Only alphanumeric, hyphen, underscore and space is allowed!',
      ),
  });

  const tokenField: FormSchema = state.isEdit
    ? {}
    : {
        token: {
          htmlInputType: 'password',
          passwordShow: true,
          type: 'text',
          label: 'OAuth Token',
          isRequired: true,
          helpComponent: <CreateOAuthToken />,
          isHelpOpen: state.tokenHelp,
          onChange: val => updateState({ validate: !!val }),
          helpIcon: (
            <Box
              onClick={() =>
                updateState({ tokenHelp: !state.tokenHelp, channelHelp: false })
              }
              _hover={{ cursor: 'pointer' }}
              color="primary"
            >
              <QuestionCircleIcon />
            </Box>
          ),
          /*error:
        clientInfo.isError && !validate
          ? 'Please enter valid OAuth token!'
          : '',*/
        },
      };

  return (
    <Form
      formValidation={schemaForm}
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          value: state.name,
          onChange: val => updateState({ name: val }),
          isDisabled: client.isLoading,
          tooltip: 'Only alphanumeric, hyphen, underscore and space is allowed',
        },
        ...tokenField,
        channel_id: {
          type: 'text',
          label: 'Channel ID',
          isRequired: true,
          value: state.channel,
          onChange: v => updateState({ channel: v }),
          helpComponent: <GetChannelID />,
          isHelpOpen: state.channelHelp,
          helpIcon: (
            <Box
              onClick={() =>
                updateState({
                  channelHelp: !state.channelHelp,
                  tokenHelp: false,
                })
              }
              _hover={{ cursor: 'pointer' }}
              color="primary"
            >
              <QuestionCircleIcon />
            </Box>
          ),
        },
      }}
      showHelp
      styles={formStyles}
      buttonOptions={{
        submit: {
          name: state.isEdit ? 'Update Channel' : 'Add Channel',
          isLoading: client.isLoading,
          isDisabled: !state.name || !state.channel || !state.validate,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: client.isLoading,
          onClick: onCancel,
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
