/**
 *
 * Cloud Member Account Card
 *
 */

import React, { memo, useEffect, useState } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  PageInfo,
  RediscoverIcon,
  TableActionsMenu,
  ChecklistIcon,
  GraphIcon,
  IconTypes,
  EditIcon,
  RemediateIcon, VulnerabilityIcon,
} from 'app/components';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { DisconnectIcon } from 'components/Icons';
import { DeleteCloudAccountModal } from 'containers/Setup/CloudAccounts/Components/DeleteCloudAccountModal';
import { GovernanceChecklist } from 'containers/Setup/CloudAccounts/Components/GovernanceChecklist';
import { useCloudAccountsFunctions } from 'containers/Setup/CloudAccounts/Components/hooks/useCloudAccountsFunctions';
import { OutpostDeployedInformation } from 'containers/Setup/CloudAccounts/Components/OutpostDeployedInformation';
import { EnableRemediation } from 'containers/Setup/CloudAccounts/Components/Remediation/EnableRemediation';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';

import { actions } from '../slice';
import { isSaaSAccount, useCloudAccountTableColumns } from '../utils';

import { AssetSummary } from './AssetSummary';
import { SnapshotTable } from './SnapshotTable';

interface Props {
  managementAccountId: string;
}

export const MemberAccounts = memo((props: Props) => {
  const { managementAccountId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const remediation = useDisclosure();
  const { memberAccounts, dataScanner } = useSelector(selectCloudAccounts);
  const {
    memberColumns,
    showDeployedOutposts,
    currentRow,
  } = useCloudAccountTableColumns();
  const [showGovernanceChecklist, setShowGovernanceChecklist] = useState(false);

  const [activeAccount, setActiveAccount] = useState<any>(null);
  const [isOpenSnapshots, setIsOpenSnapshots] = useState(false);
  const [isOpenAssetDetails, setIsOpenAssetDetails] = useState(false);
  /*const [
    showRediscoveryConfirmation,
    setShowRediscoveryConfirmation,
  ] = useState(false);*/
  const { getMemberAccounts } = useCloudAccountsFunctions();

  const updateMemberAccountPageInfo = (info: PageInfo) => {
    dispatch(actions.updateMemberAccountPageInfo(info));
  };

  useEffect(() => {
    getMemberAccounts(managementAccountId);
  }, [getMemberAccounts, managementAccountId]);

  const isAccountOnboarded = account => account?.status?.code === 'SUCCESS';
  /*const onConfirmRediscover = row => {
    dispatch(
      actions.rediscoverInventory({
        q: { uuid: row.uuid },
        onSuccess: res =>
          successToast({
            title: 'Inventory rediscovery is in progress.',
          }),
        onError: error =>
          errorToast({
            title: 'Rediscover inventory failed.',
            description: error.description,
          }),
      }),
    );
    setShowRediscoveryConfirmation(false);
  };*/

  /*const onConfirmRediscoveryClose = () => {
    setShowRediscoveryConfirmation(false);
  };*/

  const handleOnboardMemberAccount = row => {
    navigate('/setup/accounts/add/aws&member_account=true', {
      //search: '',
      state: row,
    });
  };

  const menuItems = row => [
    ...(isSaaSAccount(row)
      ? [
          {
            label: 'Show deployment',
            icon: <GraphIcon />,
            onClick: () => {
              // navigate(
              //   `/visibility/security-graph?resource_type=${''}&resource_id=${''}&source_resource_uuid=${''}&account_id=${''}&map_type=${''}`,
              // );
            },
          },
        ]
      : [
          /*{
          label: 'Deploy Outpost for Vulnerability',
          icon: <RedshiftIcon />,
          onClick: () => {
            navigate(`/setup/accounts/add/outpost/${row.uuid}`);
          },
          isDisabled: !isAccountOnboarded(row),
        },
        {
          label: 'Deploy Outpost for Data',
          icon: <DataScanIcon />,
          onClick: () => {
            navigate(`/setup/accounts/add/data-outpost/${row.uuid}`);
          },
          isDisabled: !isAccountOnboarded(row),
        },*/
        ]),
    ...(isSaaSAccount(row)
      ? [
          {
            label: 'Edit',
            icon: <EditIcon />,
            onClick: () => {
              dispatch(actions.setIsEdit(true));
              navigate(`/setup/accounts/edit/${row.cloud_type}/${row.uuid}`);
            },
            isDisabled:
              !isAccountOnboarded(row) ||
              row.cloud_type === CloudProviders.BitbucketIac ||
              row.cloud_type === CloudProviders.GithubIac,
          },
        ]
      : []),
    {
      isDisabled: isAccountOnboarded(row),
      label: <Box>Onboard</Box>,
      onClick: () => handleOnboardMemberAccount(row),
      icon: <RediscoverIcon />,
    },
    /*{
      isDisabled: !isAccountOnboarded(row),
      label: (
        <Box>
          Rediscover
          <RediscoverConfirmModal
            action="Rediscover"
            isOpen={
              showRediscoveryConfirmation &&
              activeAccount.uuid === row.uuid
            }
            onClose={onConfirmRediscoveryClose}
            onConfirm={() => onConfirmRediscover(row)}
            cloudAccount={row}
            message={'Are you sure you want to rediscover the following'}
          />
        </Box>
      ),
      onClick: () => {
        setActiveAccount(row);
        setShowRediscoveryConfirmation(true);
      },
      icon: <RediscoverIcon />,
    },*/
    // {
    //   label: 'Inventory History',
    //   icon: <HistoryIcon />,
    //   onClick: () => {
    //     setActiveAccount(row);
    //     setIsOpenSnapshots(true);
    //   },
    //   isDisabled: !isAccountOnboarded(row),
    // },
    // {
    //   label: 'Asset Summary',
    //   icon: <ResourcesIcon />,
    //   onClick: () => {
    //     setActiveAccount(row);
    //     setIsOpenAssetDetails(true);
    //   },
    //   isDisabled: !isAccountOnboarded(row),
    // },
    {
      label: (
        <Box>
          Detach
          {isOpen && activeAccount.uuid === row.uuid && (
            <DeleteCloudAccountModal
              isOpen={isOpen}
              onClose={onClose}
              account={row}
              member
              getMemberAccounts={() => getMemberAccounts(managementAccountId)}
            />
          )}
        </Box>
      ),
      onClick: () => {
        setActiveAccount(row);
        onOpen();
      },
      icon: <DisconnectIcon />,
    },
    {
      isDisabled: !isAccountOnboarded(row),
      isHidden: isSaaSAccount(row),
      label: (
        <Box>
          Governance Checklist
          {showGovernanceChecklist && activeAccount.uuid === row.uuid && (
            <GovernanceChecklist
              isOpen={showGovernanceChecklist}
              onClose={() => setShowGovernanceChecklist(false)}
              row={row}
            />
          )}
        </Box>
      ),
      onClick: () => {
        setActiveAccount(row);
        setShowGovernanceChecklist(true);
      },
      icon: <ChecklistIcon />,
    },
    {
      isDisabled: !isAccountOnboarded(row),
      isHidden: isSaaSAccount(row),
      label: 'Enable for Remediation',
      onClick: () => {
        setActiveAccount(row);
        remediation.onOpen();
      },
      icon: <RemediateIcon />,
    },
    {
      isDisabled: !isAccountOnboarded(row),
      isHidden: isSaaSAccount(row),
      label: 'Enable Central Scanning',
      onClick: () => {
        navigate(
          `/setup/accounts/enable-scan/${row?.cloud_type}/${row.uuid}`,
        );
      },
      icon: <VulnerabilityIcon />,
    },
  ];

  const renderActions = ({ row }) => {
    return (
      <Box onClick={e => e.stopPropagation()}>
        <TableActionsMenu menuItems={menuItems(row).filter(r => !r.isHidden)} />
      </Box>
    );
  };

  const columns = [
    ...memberColumns,
    {
      header: 'Actions',
      accessor: 'id',
      render: renderActions,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={memberAccounts.data}
        pagination={{
          pageInfo: memberAccounts.page.info,
          totalCount: memberAccounts.page.totalCount,
          onChange: updateMemberAccountPageInfo,
        }}
        expanded={true}
        isLoading={memberAccounts.isLoading}
        /*styles={{
          cell: {
            verticalAlign: 'top',
            border: 'none',
            ...cellStyle,
          },
          headerCell: cellStyle,
          row: {
            boxShadow: '0px -1px 0 #e2e8f0, inset 1px 0px 0 #e2e8f0',
          },
        }}*/
      />

      {isOpenSnapshots && (
        <SnapshotTable
          account_id={activeAccount.uuid}
          isOpen={isOpenSnapshots}
          onClose={() => setIsOpenSnapshots(false)}
        />
      )}

      {isOpenAssetDetails && (
        <AssetSummary
          account_id={activeAccount.uuid}
          isOpen={isOpenAssetDetails}
          onClose={() => setIsOpenAssetDetails(false)}
        />
      )}

      {showDeployedOutposts.isOpen && (
        <OutpostDeployedInformation
          isOpen={showDeployedOutposts.isOpen}
          onClose={showDeployedOutposts.onClose}
          data={dataScanner.data?.[currentRow?.uuid] ?? []}
          accountName={currentRow?.name}
          type={IconTypes.Data}
        />
      )}

      {remediation.isOpen && activeAccount && (
        <EnableRemediation
          onClose={remediation.onClose}
          account={activeAccount}
          isOpen={remediation.isOpen}
        />
      )}
    </>
  );
});
