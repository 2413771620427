import React, { FC } from 'react';

import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { colorOpacity, customTheme } from 'theme';

import { PrimaryButton } from 'components/DataEntry';
import { contentStyles } from 'components/FeatureDemo/styles';
import {
  CompanyLogoIcon,
  AutomationIcon,
  CloudIcon,
  DataScanIcon,
  HealthIcon,
  TriangleRightIcon,
  VulnerabilityIcon,
} from 'components/Icons';

interface ISetup {
  onClose();

}

const COLOR = colorOpacity(customTheme.colors.primary, 0.3);

export const Setup: FC<ISetup> = props => {
  const { onClose } = props;
  const list = [
    {
      header: 'Onboard Cloud Accounts',
      description: [
        'Onboard your AWS, GCP, Azure and SaaS accounts in just a couple of minutes',
      ],
      icon: <CloudIcon />,
    },
    {
      header: 'Improve Cloud Governance',
      description: [
        'Proactively evaluate account governance against best practices for security and compliance',
      ],
      icon: <HealthIcon p={0.5} />,
    },
    {
      header: 'Patch Exploitable Software',
      description: [
        'Automatically detect and focus on only exploitable software ' +
          'vulnerabilities that can be patched and create real attack paths',
      ],
      icon: <VulnerabilityIcon />,
    },
    {
      header: 'Enforce Data Governance',
      description: [
        'Automatically classify your structured & unstructured content by ' +
          'documents and data types. Enforce data access and governance',
      ],
      icon: <DataScanIcon p={1} />,
    },
    {
      header: 'Accelerate response',
      description: [
        'Automatically route issues to owners on Slack, JIRA and/or emails. ' +
          'Accelerate productivity of security teams',
      ],
      icon: <AutomationIcon p={1} />,
    },
  ];

  return (
    <Stack {...contentStyles.root} w="full">
      <Flex {...contentStyles.logoWrapper}>
        {/* Logo */}
        <Center my="auto" {...contentStyles.logo}>
          <CompanyLogoIcon />
        </Center>
      </Flex>
      <Box fontWeight={600} fontSize="xl" pl={6}>
        Making the most of Ariksa…
      </Box>
      <Stack {...contentStyles.content}>
        <HStack justify="space-between" w="full" pt={16}>
          {map(list, (o, index) => (
            <>
              <Stack spacing={0} h="full">
                <Box
                  w="14.5rem"
                  h="full"
                  border="1px solid"
                  borderColor="primary"
                  borderRadius={6}
                  bg="white"
                  overflow="visible"
                  pt={10}
                  pb={12}
                  pl={7}
                  pr={6}
                  boxShadow={`1px 6px 6px ${customTheme.colors.gray['100']}`}
                >
                  <Stack spacing={4} w="full">
                    <HStack
                      fontWeight={600}
                      fontSize="md"
                      color="primary"
                      w="full"
                      align="flex-start"
                    >
                      <Box boxSize={10} pt={1}>
                        <Center>{o.icon}</Center>
                      </Box>
                      <Box>{o.header}...</Box>
                    </HStack>
                    <Box borderBottom="1px solid" borderColor="primary" />
                    <Stack h="full" pt={2}>
                      {map(o.description, d => (
                        <Box fontSize="md">{d}</Box>
                      ))}
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
              {index < list?.length - 1 && (
                <Box color={COLOR} boxSize={12}>
                  <TriangleRightIcon />
                </Box>
              )}
            </>
          ))}
        </HStack>
        <Flex {...contentStyles.buttonGroup} gap={6} pt={16}>
          <PrimaryButton
            {...contentStyles.button}
            onClick={() => {
              onClose();
              //navigate('/setup/accounts');
            }}
          >
            GET STARTED
          </PrimaryButton>
        </Flex>
      </Stack>
    </Stack>
  );
};
