import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  CustomTooltip,
  getIcon,
  getProviderName,
  renderRiskContext,
  renderTableHeaderWithLoader,
  renderTooltipFieldValue,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { GlobeIcon, PageInfo } from 'components/index';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { renderVulnerabilities } from 'containers/Inventory/CloudInventory/Components/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

export const AMIHosts: FC = () => {
  const { amiHosts } = useSelector(selectActiveResource);
  const { hosts, vulnerabilities, riskContext } = amiHosts;
  const { accountMapping } = useCloudAccountId();
  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();

  const dispatch = useDispatch();

  const columns = [
    {
      header: <Box pl={12}>Host</Box>,
      render: ({ row }) => (
        <WithResourceIcon resourceType={row?.native_name}>
          <StackedCell upper={row?.name} lower={row?.resource_id} />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Account',
      render: ({ row }) => {
        const account = accountMapping?.[row?.account];
        return (
          <StackedCell
            upper={account?.name}
            lower={account?.cloud_account_id}
          />
        );
      },
      align: 'left',
    },
    {
      header: 'Deployed in',
      render: ({ row }) => (
        <CustomTooltip
          label={
            <Stack>
              {renderTooltipFieldValue(
                'Provider',
                getProviderName(row?.provider),
              )}
              {renderTooltipFieldValue('VPC Name', row?.vpc)}
              {renderTooltipFieldValue('VPC ID', row?.vpc_id)}
            </Stack>
          }
        >
          <StackedCell
            upper={<Box boxSize={5}>{getIcon(row?.provider)}</Box>}
            lower={row?.vpc ?? row?.vpc_id}
            showLowerTooltip={false}
            showUpperTooltip={false}
          />
        </CustomTooltip>
      ),
      align: 'left',
    },
    {
      header: 'IP Address',
      render: ({ row }) => (
        <StackedCell
          upper={
            row?.public_ip_address ? (
              <HStack>
                <Box boxSize={5} color="critical">
                  <GlobeIcon />
                </Box>
                <Box>{row?.public_ip_address}</Box>
              </HStack>
            ) : (
              '-'
            )
          }
          lower={row?.private_ip_address}
        />
      ),
      align: 'left',
    },
    {
      header: renderTableHeaderWithLoader(
        'Vulnerabilities',
        some(vulnerabilities, { isLoading: true }),
      ),
      render: ({ row }) =>
        renderVulnerabilities({
          value:
            vulnerabilities?.[row.native_name]?.data?.[row?.uuid]
              ?.vulnerabilities,
        }),
      align: 'left',
    },
    {
      header: renderTableHeaderWithLoader(
        'Risk Context',
        riskContext.isLoading,
      ),
      render: ({ row }) => renderRiskContext(riskContext.data?.[row?.uuid]),
      align: 'left',
    },
  ];

  return (
    <Box h="full">
      <Table
        columns={columns}
        data={hosts.data}
        isLoading={hosts.isLoading}
        styles={{ height: '33vh' }}
        pagination={{
          pageInfo: hosts.page.info,
          totalCount: hosts.page.totalCount,
          onChange: (info: PageInfo) =>
            dispatch(actions.updateAMIHostsPagination(info)),
        }}
        onRowClick={row => {
          updateActiveResource({
            resourceType: row?.native_name as NativeResources,
            uuid: row?.uuid,
            accountId: row.account,
            resourceId: row.resource_id,
          });
          onOpenMetadata();
        }}
      />
    </Box>
  );
};
