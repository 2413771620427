/**
 *
 * Add Ariksa Outpost
 *
 */

import React, { FC, useEffect, useState } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Screens } from '@ariksa/inventory-core';
import {
  Box,
  Center,
  Flex,
  HStack,
  ListItem,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { customTheme } from 'theme';
import { v4 as uuidv4 } from 'uuid';

import { authOptions } from 'api/auth';
import { VerifyService } from 'api/auth/api.pb';
import { queryStringFromObject } from 'api/utils';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { Form, FormSchema } from 'components/DataEntry/Form';
import {
  CheckmarkCircleIcon,
  CopyIcon,
  errorToast,
  PrimaryButton,
  QuestionCircleIcon,
} from 'components/index';
import { selectUser } from 'containers/App/selectors';
import { selectOutpost } from 'containers/Setup/Outpost/selectors';
import { OutpostProps } from 'containers/Setup/Outpost/types';
import { selectSetup } from 'containers/Setup/selectors';
import { actions as setupActions } from 'containers/Setup/slice';
import { selectSharedState } from 'containers/SharedState/selectors';

import { formStyles } from '../../../../CloudAccounts/CloudAccountWizard/styles';

export const AddAriksaOutpostForVulnerabilityForm: FC<OutpostProps> = props => {
  const {
    accountOptions,
    selectedAccount,
    setSelectedAccount,
    regionOptions,
    selectedTab,
    selectedRegion,
    setSelectedRegion,
    selectedZone,
    setSelectedZone,
    zoneOptions,
  } = props;
  const navigate = useNavigate();
  const { host } = useLocation();
  const [sharedSecret, setSharedSecret] = useState('');
  const { info } = useSelector(selectUser);
  const { outpostToken, zones } = useSelector(selectOutpost);
  const { configCommands, outpostConfig } = useSelector(selectSetup);
  const { regions } = useSelector(selectSharedState);
  const [isCopied, setIsCopied] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      setIsCopied(false);
    }, 9000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!!sharedSecret && !!selectedZone?.value) {
      dispatch(
        setupActions.getConfigCommands({
          q: {
            screen: Screens.VulnerabilityScannerDeployment,
            zone: selectedZone?.value,
            sharedSecret: `${info.org_id}::${
              host?.split('.')[0]
            }::${sharedSecret}`,
          },
        }),
      );
    }
  }, [selectedZone?.value, sharedSecret, dispatch, info.org_id, host]);

  const handleGenerateToken = () => {
    VerifyService.GetClientCredentials({}, authOptions())
      .then(res => {
        setSharedSecret(res.client_secret ?? '');
      })
      .catch(err => {
        errorToast({
          title: err.message,
        });
      });
  };

  const handleSubmit = data => {
    const { region } = data;
    if (!sharedSecret) {
      errorToast({ title: 'Outpost token is not generated' });
      return;
    }

    const parameters = {
      stackName: `Ariksa-Outpost-Vuln-${
        selectedAccount.data?.cloud_account_id
      }-${uuidv4()}`,
      templateURL: outpostConfig.data?.cloud_template_map?.[selectedTab],
      //'https://ezl7u4ytyo-s5cwguevl7vglyy8y-hd1s8.s3.amazonaws.com/ariksa-outpost-cloudformation.yaml',
      param_SharedSecret: `${info.org_id}::${
        host?.split('.')[0]
      }::${sharedSecret}`,
    };

    let redirectUrl = `https://${
      region?.value
    }.console.aws.amazon.com/cloudformation/home?region=${
      region?.value
    }#/stacks/quickcreate${queryStringFromObject(parameters, {
      useSnakeCase: false,
    })}`;

    if (selectedTab === CloudProviders.Gcp) {
      redirectUrl = 'https://console.cloud.google.com';
    }

    navigate('/setup/outpost');
    window.open(redirectUrl, '_blank');
  };

  const handleReset = () => {
    navigate('/setup/outpost');
  };

  const containerStyles = {
    border: '1px solid',
    borderColor: customTheme.colors.gray['200'],
    py: 1,
    px: 3,
    w: 'full',
    borderRadius: '3px',
    overflow: 'auto',
  };

  const zone: FormSchema =
    selectedTab === CloudProviders.Gcp
      ? {
          zone: {
            type: 'react-select',
            options: zoneOptions,
            value: selectedZone,
            onChange: setSelectedZone,
            label: 'Zone',
            isLoading: zones.isLoading,
            isDisabled: regions.isLoading || zones.isLoading,
          },
        }
      : {};

  const gcpCommand: FormSchema =
    selectedTab === CloudProviders.Gcp &&
    !!sharedSecret &&
    !!outpostConfig.data &&
    !!selectedZone?.value
      ? {
          gcpCommand: {
            type: 'custom-with-form-control',
            label: 'Copy and paste the following script in Cloudshell',
            component: (
              <Box p={4} borderRadius={6} bg={customTheme.colors.gray['50']}>
                <HStack justify="space-between" align="flex-start" w="full">
                  <Box flex={1}>
                    {configCommands?.isLoading ? (
                      <CustomSpinner />
                    ) : (
                      configCommands.data?.cloud_commands_map?.gcp
                        ?.vulnerability_scanner_deployment?.deployment
                    )}
                  </Box>
                  <Box
                    boxSize={6}
                    color={
                      isCopied ? customTheme.colors.green['300'] : 'primary'
                    }
                    cursor={isCopied ? 'default' : 'pointer'}
                  >
                    <Center w="full" h="full">
                      <CustomTooltip label="Copy command">
                        {isCopied ? (
                          <CustomTooltip label="Copied to clipboard">
                            <CheckmarkCircleIcon />
                          </CustomTooltip>
                        ) : (
                          <CopyIcon
                            onClick={() => {
                              navigator.clipboard.writeText(
                                configCommands.data?.cloud_commands_map?.gcp
                                  ?.vulnerability_scanner_deployment
                                  ?.deployment,
                              );
                              setIsCopied(true);
                            }}
                          />
                        )}
                      </CustomTooltip>
                    </Center>
                  </Box>
                </HStack>
              </Box>
            ),
          },
        }
      : {};

  return (
    <>
      <Form
        schema={{
          account_name: {
            type: 'react-select',
            label: 'Account',
            onChange: s => {
              setSelectedAccount(s);
              setSelectedRegion({});
              setSelectedZone({});
            },
            options: accountOptions,
            value: selectedAccount,
          },
          region: {
            type: 'react-select',
            options: regionOptions,
            value: selectedRegion,
            onChange: s => {
              setSelectedRegion(s);
              //setZoneOptions([]);
              setSelectedZone({});
            },
            label: 'Region',
            isLoading: regions.isLoading,
            isDisabled: regions.isLoading,
          },
          ...zone,
          role_arn_creation: {
            type: 'custom-with-form-control',
            label: 'Authentication Token for Outpost',
            component: (
              <HStack w="full">
                <Box {...containerStyles} h={8} fontSize="22px" flex={1}>
                  {sharedSecret
                    ? '****************************************'
                    : ''}
                </Box>
                <Flex justify="flex-end">
                  <PrimaryButton onClick={handleGenerateToken}>
                    Generate token
                  </PrimaryButton>
                </Flex>
              </HStack>
            ),
          },
          ...gcpCommand,
          note: {
            type: 'custom',
            component: () =>
              selectedTab === CloudProviders.Gcp ? (
                <Stack color="primary">
                  <Box fontWeight={600} fontSize="md">
                    NOTE:
                  </Box>
                  <Box>
                    You will be redirected to GCP console. Copy and paste the
                    script in Cloudshell.
                  </Box>
                </Stack>
              ) : (
                <Stack spacing={0} color="primary" fontSize="md">
                  <Box fontWeight={600} fontSize="md">
                    NOTE:
                  </Box>
                  <Box>
                    You will be redirected to login to your AWS account to
                    provide inputs required for deploying Ariksa Output for
                    Vulnerability. You’ll need to pick the following:
                  </Box>
                  <Box>
                    <UnorderedList>
                      <ListItem>Virtual Private Cloud (VPC)</ListItem>
                      <ListItem>Subnet</ListItem>
                    </UnorderedList>
                  </Box>
                </Stack>
              ),
          },
        }}
        buttonOptions={{
          submit: {
            name: '+ Add Outpost',
            isLoading: outpostToken.isLoading || configCommands.isLoading,
            isDisabled:
              !sharedSecret ||
              outpostToken.isLoading ||
              configCommands.isLoading ||
              !outpostToken.data ||
              isEmpty(regions.data),
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        styles={formStyles()}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
