import { TooltipProps } from '@chakra-ui/react';
import { colorMap, customTheme } from 'theme';

import { defaultStyles } from 'components/DataEntry/Form/styles';

import { PopoverStyles } from '../../Overlay';

export const buttonStyles = (bg, border = '#aaa') => {
  return {
    size: 'sm',
    fontWeight: 'sm',
    variant: 'outline',
    border: '1px',
    bg: bg,
    color: 'white',
    borderColor: border,
    _active: {
      bg: bg,
      color: 'white',
      borderColor: bg,
    },
    _hover: {
      bg: bg,
      color: 'white',
      borderColor: bg,
    },
    _focus: {
      outline: 'none',
      bg: bg,
      color: 'white',
      boxShadow: `0 0 0 2px ${customTheme.colors[bg]}`,
      borderColor: bg,
    },
  };
};

export const primaryButtonStyles = {
  ...buttonStyles('primary'),
  _hover: { bg: colorMap.primary(), color: '#fff' },
};
export const editButtonStyles = buttonStyles('edit');
export const deleteButtonStyles = buttonStyles('delete');
export const customColorButtonStyles = color => buttonStyles(color);
export const cancelButtonStyles = defaultStyles?.form?.backButton;

export const primaryIconButtonStyles = {
  ...primaryButtonStyles,
  borderRadius: 'full',
  p: 1.5,
};

export const passwordFieldIconStyles = {
  size: 'sm',
  color: 'primary',
  bg: 'transparent',
  _hover: {
    bg: 'transparent',
  },
  _active: {
    bg: 'transparent',
  },
  _focus: {
    outline: 'none',
    bg: 'transparent',
  },
  p: 1,
};

export const grayIconButtonStyles = {
  size: 'sm',
  fontWeight: 'sm',
  _focus: {
    outline: 'none',
    boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
  },
  borderRadius: 'full',
  p: 1,
};

export const actionButtonHoverStyles = isDisabled => ({
  bg: isDisabled ? '' : 'hover',
  borderColor: '#eee',
  cursor: isDisabled ? 'not-allowed' : 'pointer',
});

export const actionButtonStyles = {
  borderRadius: 'full',
  w: 7,
  h: 7,
  bg: 'transparent',
  border: '1px solid transparent',
  space: 0,
  cursor: 'pointer',
  color: 'primary',
  p: 1,
};

export const tooltipProps: Omit<TooltipProps, 'children'> = {
  hasArrow: true,
  fontSize: 'sm',
  placement: 'top-start',
  borderRadius: 6,
  p: 2,
};

export const deletePopConfirmStyles: PopoverStyles = {
  arrow: {},
  content: {
    w: 64,
    ml: 0,
    mt: 8,
  },
  footer: {
    borderTop: 'none',
    pt: 0,
  },
};

export const modalCloseButtonIconStyles = {
  ...primaryButtonStyles,
  size: 'sm',
};
