import React, { FC, useRef } from 'react';

import { Box } from '@chakra-ui/react';

import { Drawer } from 'components/Overlay';
import { MetadataHeader } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataHeader';
import { ResourceMetadata } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

interface Props {
  onBack?();
}

export const NodeMetadataDrawer: FC<Props> = props => {
  const { onBack } = props;
  const {
    onCloseMetadata,
    isOpenMetadata,
    onOpenMetadata,
  } = useActiveResourceContext();
  const ref = useRef(document.querySelector('.portal-container'));

  return (
    <Drawer
      onOpen={onOpenMetadata}
      isOpen={isOpenMetadata}
      onClose={onCloseMetadata}
      header={<MetadataHeader onBack={onBack} />}
      body={
        <Box h="full" w="full" pt={3}>
          <ResourceMetadata />
        </Box>
      }
      closeButton
      styles={{
        content: { maxW: '1500px' },
        drawer: { portalProps: { containerRef: ref as any } },
      }}
    />
  );
};
