import React, { FC } from 'react';

import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';

import { PrimaryButton } from 'components/DataEntry/Button';
import { contentStyles } from 'components/FeatureDemo/styles';
import {
  AnomalousBehaviourIcon,
  CompanyLogoIcon,
  DatabaseIcon,
  GlobeIcon,
  UserIcon,
} from 'components/Icons';

import { customTheme } from '../../../../theme';

interface IOverview {
  next();
}

export const Overview: FC<IOverview> = props => {
  const list = [
    {
      header: 'Detect Multi-\nDimensional Threats',
      description:
        'Ariksa maximizes threat and risk correlation spanning applications, ' +
        'software vulnerabilities, identities, credentials, infrastructure, and sensitive data',
      icon: <AnomalousBehaviourIcon p={1} />,
      headerPadding: 2,
    },
    {
      header: 'Automatically Triage Threats for Prioritization',
      description:
        'Ariksa provides superior prioritization of threats by using enriched context, ' +
        'static and runtime information from built-in integrations and lightweight sensors',
      icon: <GlobeIcon />,
    },
    {
      header: 'Visually Investigate Attack Paths',
      description:
        'Ariksa leverages its purpose-built knowledge fabric for cloud security to ' +
        'generate visual graphs with 360º risk context for rapid and intuitive investigation',
      icon: <DatabaseIcon p={1} />,
      headerPadding: 2,
    },
    {
      header: 'Automated Remediation',
      description:
        'Ariksa provides custom\n' +
        'policy generation and\n' +
        'automated remediation with\n' +
        'strict policy guardrails for\n' +
        'accurate, accelerated\n' +
        'response and low MTTR',
      icon: <UserIcon p={1} />,
      headerPadding: 8,
    },
  ];

  return (
    <Stack {...contentStyles.root}>
      <Flex {...contentStyles.logoWrapper}>
        {/* Logo */}
        <Center my="auto" {...contentStyles.logo}>
          <CompanyLogoIcon />
        </Center>
      </Flex>
      <Box fontWeight={600} fontSize="xl" pl={6}>
        Welcome to Ariksa
      </Box>
      <Stack {...contentStyles.content}>
        <Box>
          We provide breadth of coverage, deep automated analysis and automated
          remediation of threats to your cloud environment
        </Box>
        <HStack justify="space-between" pt={6}>
          {map(list, (o, index) => (
            <Stack spacing={0} h="full" key={index + '-key-feature-demo'}>
              <Center>
                <Box
                  boxSize={8}
                  position="absolute"
                  borderRadius="full"
                  bg="white"
                />
              </Center>
              <Center w="full" position="relative">
                <Box
                  boxSize={12}
                  bg="white"
                  color="critical"
                  p={2}
                  position="absolute"
                  borderRadius="full"
                  border="1px solid"
                  borderColor="primary"
                >
                  <Center>{o?.icon}</Center>
                </Box>
              </Center>
              <Box
                w={72}
                h="full"
                border="1px solid"
                borderColor="primary"
                borderRadius={6}
                bg="white"
                overflow="visible"
                pt={14}
                pb={10}
                px={6}
                boxShadow={`1px 6px 6px ${customTheme.colors.gray['100']}`}
              >
                <Stack spacing={4} w="full">
                  <Box
                    fontWeight={600}
                    fontSize="lg"
                    color="primary"
                    w="full"
                    px={o?.headerPadding}
                  >
                    <Flex w="full" textAlign="center">
                      {o.header}
                    </Flex>
                  </Box>
                  <Box borderBottom="1px solid" borderColor="primary" />
                  <Box fontSize="md" pt={3} px={6}>
                    {o?.description}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          ))}
        </HStack>
        <Flex {...contentStyles.buttonGroup}>
          <PrimaryButton {...contentStyles.button} onClick={props.next}>
            NEXT
          </PrimaryButton>
        </Flex>
      </Stack>
    </Stack>
  );
};
