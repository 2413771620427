import React from 'react';

import { Box, Stack, HStack } from '@chakra-ui/react';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { AccountIcon } from 'components/Icons';
import { SubComponentContainer } from 'containers/Compliance/Components/ComplianceTables/SubComponentContainer';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectOutpost } from 'containers/Setup/Outpost/selectors';

import { actions } from '../slice';

interface Props {
  row: any;
  isExpanded: boolean;
}

export const OutpostSubComponent: React.FC<Props> = props => {
  const { row, isExpanded } = props;
  const dispatch = useDispatch();
  const { accountsScanned } = useSelector(selectOutpost);
  const { accountMapping } = useCloudAccountId();

  useDeepCompareEffect(() => {
    isExpanded &&
      row?.type === 'Vulnerability' &&
      dispatch(
        actions.getAccountsScanned({ q: { accountUuid: row?.account_id } }),
      );
  }, [dispatch]);

  return (
    <SubComponentContainer>
      {accountsScanned?.[row?.account_id]?.isLoading ? (
        <CustomSpinner />
      ) : (
        <Stack spacing={4} pl={12}>
          <Box fontWeight={600}>Accounts being scanned by this Outpost</Box>
          {map(accountsScanned?.[row?.account_id]?.data?.cross_accounts, o => {
            const account = accountMapping[o];
            return (
              <HStack>
                <Box boxSize={6} color="primary">
                  <AccountIcon />
                </Box>
                <Box>
                  {account?.name}: {account.cloud_account_id}
                </Box>
              </HStack>
            );
          })}
        </Stack>
      )}
    </SubComponentContainer>
  );
};
