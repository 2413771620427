import React, { FC, useState } from 'react';

import { Flex } from '@chakra-ui/react';

import { Onboard } from 'components/FeatureDemo/components/Onbaord';
import { SideNavKey } from 'components/Navigation';

import { Overview } from './components/Overview';
import { Setup } from './components/Setup';

interface IFeatureDemo {
  onClose();
}

export const FeatureDemo: FC<IFeatureDemo> = props => {
  const { onClose } = props;
  const [activeTab, setActiveTab] = useState(0);

  const handleNext = () => {
    setActiveTab(activeTab + 1);
  };

  return (
    <Flex h={window.innerHeight + 'px'} pl={10}>
      {activeTab === 0 ? (
        <Overview next={handleNext} />
      ) : activeTab === 1 ? (
        <Setup onClose={handleNext} />
      ) : (
        <Onboard />
      )}
    </Flex>
  );
};
