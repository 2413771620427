/**
 *
 * Add Ariksa Outpost
 *
 */

import React, { FC, useEffect, useReducer } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Outpost } from '@ariksa/inventory-core/api';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { filter, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { queryStringFromObject } from 'api/utils';
import { Card, getIcon, PageHeaderWithIcon } from 'components/DataDisplay';
import { Form } from 'components/DataEntry/Form';
import { GraphIcon, QuestionCircleIcon } from 'components/Icons';
import { selectApp, selectUser } from 'containers/App/selectors';
import { EnablingAccountForOutpostHelp } from 'containers/Setup/CloudAccounts/Components/CentralScanning/AboutAriksaOutpostForVulnerability';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSetup } from 'containers/Setup/selectors';
import { actions as setupActions } from 'containers/Setup/slice';

export const EnableCentralScanning: FC = props => {
  const navigate = useNavigate();
  const { info } = useSelector(selectUser);
  const { cloudAccounts } = useSelector(selectApp);
  const { outpostConfig } = useSelector(selectSetup);
  const dispatch = useDispatch();
  const params = useParams<{ uuid: string; provider: string }>();
  const { accountMapping } = useCloudAccountId();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      accountOptions: [],
      outpostAccountOptions: [],
      selectedAccount: {},
      selectedOutpostAccount: {},
      showHelp: false,
      isMember: false,
    },
  );

  useEffect(() => {
    dispatch(
      setupActions.getOutpostConfig({
        q: {
          outpost: Outpost.Vulnerability,
        },
      }),
    );
  }, [dispatch]);

  //set outpost account
  useEffect(() => {
    if (!params.uuid) return;
    const account = accountMapping[params.uuid];

    if (!!account) {
      const option = {
        label: account.name + ' (Account ID: ' + account.cloud_account_id + ')',
        value: account.uuid,
        data: account,
        icon: getIcon(account.cloud_type!),
      };
      updateState({
        selectedOutpostAccount: option,
        outpostAccountOptions: [option],
        isMember: !!account?.parent,
      });
    }
  }, [dispatch, params.uuid, accountMapping]);

  //set account options
  useEffect(() => {
    let options = map(
      filter(
        cloudAccounts.data,
        o => o.cloud_type === params?.provider && o.uuid !== params?.uuid,
      ),
      o => ({
        label: o.name + ' (Account ID: ' + o.cloud_account_id + ')',
        value: o.uuid,
        data: o,
        icon: getIcon(o.cloud_type!),
      }),
    );
    updateState({ accountOptions: options, selectedAccount: options?.[0] });
  }, [params?.provider, params?.uuid, cloudAccounts]);

  const handleSubmit = () => {
    const parameters = {
      stackName: `Ariksa-Cross-Account-${
        state.selectedAccount.data?.cloud_account_id
      }-${uuidv4()}`,
      templateURL:
        outpostConfig.data?.[
          state.isMember
            ? 'cross_account_member_account_config_template_map'
            : 'cross_account_management_config_template_map'
        ]?.[params.provider!],
      param_ExternalID: `cross-account:@${info.org_uuid}`,
      ...(state.isMember
        ? {
            param_VulnerabilityScannerAccountID:
              state.selectedOutpostAccount.data.cloud_account_id,
          }
        : {}),
    };

    let redirectUrl = `https://us-west-2.console.aws.amazon.com/cloudformation/home?region=us-west-2#/stacks/quickcreate${queryStringFromObject(
      parameters,
      {
        useSnakeCase: false,
      },
    )}`;

    if (params.provider === CloudProviders.Gcp) {
      redirectUrl = 'https://console.cloud.google.com';
    }

    navigate('/setup/accounts');
    window.open(redirectUrl, '_blank');
  };

  const handleReset = () => {
    navigate('/setup/accounts');
  };

  return (
    <Card>
      <HStack align="flex-start" justify="space-between" w="full">
        <Stack spacing={6} pl={32} pt={6}>
          <HStack>
            <PageHeaderWithIcon
              label={
                <HStack>
                  <Box>Enable Outpost Scanning From Central Account</Box>
                  <Box
                    onClick={() => updateState({ showHelp: !state.showHelp })}
                    color="primary"
                    cursor="pointer"
                  >
                    <QuestionCircleIcon />
                  </Box>
                </HStack>
              }
              icon={<GraphIcon />}
            />
          </HStack>
          <Form
            schema={{
              ...(state.isMember
                ? {
                    account_name: {
                      type: 'react-select',
                      label: 'Account to Scan',
                      onChange: s => {
                        updateState({ selectedAccount: s });
                      },
                      options: state.accountOptions,
                      value: state.selectedAccount,
                    },
                  }
                : {}),
              centralAccount: {
                type: 'react-select',
                label: 'Outpost Account',
                onChange: s => {
                  updateState({ selectedOutpostAccount: s });
                },
                options: state.outpostAccountOptions,
                value: state.selectedOutpostAccount,
                showIconInValueContainer: true,
                isDisabled: true,
              },
              note: {
                type: 'custom',
                component: () => (
                  <Stack spacing={0} color="primary" fontSize="md">
                    <Box fontWeight={600} fontSize="md">
                      NOTE:
                    </Box>
                    <Box>
                      You will be redirected to login to your AWS account to
                      create a cross-account role in the account to scan giving
                      permissions for the Outpost account.
                    </Box>
                  </Stack>
                ),
              },
            }}
            buttonOptions={{
              submit: {
                name: 'Enable Scan',
                isLoading: outpostConfig.isLoading,
                isDisabled:
                  outpostConfig.isLoading ||
                  !outpostConfig.data
                    ?.cross_account_member_account_config_template_map?.[
                    params.provider!
                  ],
                /*isDisabled:
              !sharedSecret ||
              outpostToken.isLoading ||
              configCommands.isLoading,*/
              },
              reset: {
                name: 'Cancel',
                isVisible: true,
                onClick: handleReset,
              },
            }}
            //styles={formStyles()}
            handleSubmit={handleSubmit}
          />
        </Stack>
        {state.showHelp && (
          <Box w="lg">
            <EnablingAccountForOutpostHelp
              onClose={() => updateState({ showHelp: !state.showHelp })}
            />
          </Box>
        )}
      </HStack>
    </Card>
  );
};
