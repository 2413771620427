import React, { FC } from 'react';

import { VulnerabilityType } from '@ariksa/scan-analysis';
import { LibraryType, PackageSummary } from '@ariksa/scan-analysis/api';
import {
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/all';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { getIcon, WithResourceIcon } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { Drawer } from 'components/Overlay';
import { MetadataHeader } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataHeader';
import { renderVulnerabilities } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

import { VulnerabilityDetails } from './VulnerabilityDetails';

export interface Props {
  type?: VulnerabilityType;
  summary: PackageSummary;
  containerId?: string;
  instanceId?: string;
  isExpanded?: boolean;
}

export const VulnerabilitySummaryCard: FC<Props> = props => {
  const { type, summary, containerId, instanceId, isExpanded } = props;
  const {
    vulnerabilitySummary,
    instanceSummaryByID,
    libraryType,
  } = useSelector(selectActiveResource);
  const { data, isLoading } = vulnerabilitySummary;
  const dispatch = useDispatch();
  const expandedView = useDisclosure();

  const getTitle = () => {
    let title: string = 'OS PACKAGES';
    switch (type) {
      case VulnerabilityType.Application:
        title = 'TECHNOLOGY';
        break;
      case VulnerabilityType.Library:
        title = 'LIBRARY PACKAGES';
        break;
    }
    return title;
  };
  const renderIcon = iconType => {
    const styles =
      libraryType === iconType
        ? {
            border: '1px solid',
            borderColor: 'primary',
          }
        : {
            border: 'none',
          };
    return (
      <Box
        onClick={e => {
          e.stopPropagation();
          let value = iconType;
          if (value === libraryType) value = undefined;
          dispatch(actions.updateLibraryType(value));
        }}
      >
        <CustomTooltip label={iconType}>
          <Center boxSize={6} p={1} borderRadius={6} {...styles}>
            {getIcon(iconType)}
          </Center>
        </CustomTooltip>
      </Box>
    );
  };

  const renderHeader = isExpanded => (
    <Grid templateColumns="repeat(9, 1fr)" gap={1} w="full">
      <GridItem colSpan={2} w="full">
        <WithResourceIcon
          resourceType={
            (type === VulnerabilityType.Os && !containerId
              ? data?.name
              : type) || ''
          }
          bgColor="none"
          iconFilled={type !== VulnerabilityType.Os}
        >
          <HStack>
            <Text color={customTheme.colors.gray['300']}>{getTitle()}</Text>
            <Text>({summary?.total ?? 0})</Text>
          </HStack>
        </WithResourceIcon>
      </GridItem>
      <GridItem colSpan={2} w="full">
        <HStack h="full">
          <Box>Patches required:</Box>
          {!!summary?.available_patches ? (
            <Center
              h={6}
              bg={customTheme.colors.gray['100']}
              p={2}
              borderRadius={6}
            >
              {summary?.available_patches}
            </Center>
          ) : (
            <Box color={customTheme.colors.gray['250']}>None</Box>
          )}
        </HStack>
      </GridItem>
      <GridItem colSpan={5}>
        <HStack justifyContent="space-between" w="full" h="full">
          {renderVulnerabilities(
            summary,
            isLoading || instanceSummaryByID.isLoading,
          )}

          <HStack>
            {type === VulnerabilityType.Library && (
              <HStack>
                {renderIcon(LibraryType.Python)}
                {renderIcon(LibraryType.Java)}
                {renderIcon(LibraryType.Golang)}
                {renderIcon(LibraryType.Ruby)}
                {renderIcon(LibraryType.Nodejs)}
              </HStack>
            )}
            {/*{!expandedView.isOpen && (
              <PrimaryIconButton
                aria-label="view all"
                tooltip={'View all'}
                p={2}
                onClick={e => {
                  e.stopPropagation();
                  expandedView.onOpen();
                  //onExpand?.();
                }}
              >
                {getIcon(IconTypes.ViewAll)}
              </PrimaryIconButton>
            )}*/}
            {!expandedView.isOpen && (
              <Center>
                {isExpanded ? <FiChevronDown /> : <FiChevronRight />}
              </Center>
            )}
          </HStack>
        </HStack>
      </GridItem>
    </Grid>
  );
  const renderPanel = (isExpanded: boolean) => {
    return (
      <VulnerabilityDetails
        vulnerabilityType={type!}
        total={summary?.total || 0}
        containerId={containerId}
        instanceId={instanceId}
        isExpanded={isExpanded}
      />
    );
  };

  return (
    <>
      <Box
        w="full"
        px={4}
        py={2}
        border="1px solid"
        borderColor={customTheme.colors.gray['100']}
        borderRadius={6}
        boxShadow={`5px 5px 1px ${customTheme.colors.gray['50']}`}
        onClick={() => expandedView.onOpen()}
        cursor="pointer"
      >
        {renderHeader(isExpanded)}
      </Box>
      {/*<AccordionItem {...metaAccordionStyles.item}>
        {({ isExpanded }) => (
          <Box {...metaAccordionStyles.innerWrapper}>
            <AccordionButton {...metaAccordionStyles.button}>
              {renderHeader(isExpanded)}
            </AccordionButton>
            {isExpanded && (
              <AccordionPanel mt={2}>{renderPanel(false)}</AccordionPanel>
            )}
          </Box>
        )}
      </AccordionItem>*/}
      <Drawer
        isOpen={expandedView.isOpen}
        onClose={expandedView.onClose}
        header={<MetadataHeader />}
        body={
          <Stack h="full" spacing={5} pt={3}>
            {type === VulnerabilityType.Os && (
              <DetailsField
                label="Operating system"
                value={
                  <HStack>
                    <Box boxSize={7}>{getIcon(data?.name!)}</Box>
                    <Box>
                      {data.name} {data.version}
                    </Box>
                  </HStack>
                }
                align="center"
              />
            )}
            {type === VulnerabilityType.Application && (
              <DetailsField
                label="Technologies deployed"
                value={
                  <HStack>
                    {/*{map(summary?.a, o => (
                      <Box boxSize={6}>{getIcon(o)}</Box>
                    ))}*/}
                  </HStack>
                }
                align="center"
              />
            )}
            {type === VulnerabilityType.Library && (
              <DetailsField
                label="Libraries detected"
                value={
                  <HStack>
                    {/* {map(summary?.libraries, o => (
                      <Box boxSize={6}>{getIcon(o)}</Box>
                    ))}*/}
                  </HStack>
                }
                align="center"
              />
            )}
            <DetailsField
              label="Vulnerabilities"
              value={renderVulnerabilities(
                summary,
                isLoading || instanceSummaryByID.isLoading,
              )}
              pb={1}
            />
            <DetailsField
              label="Patches required"
              value={summary?.available_patches}
            />
            <Box flex={1} h="full">
              {renderPanel(true)}
            </Box>
          </Stack>
        }
        closeButton
        styles={{ content: { maxW: '1200px' } }}
      />
    </>
  );
};
