import { FileDataApiGetScannersRequest } from '@ariksa/data-scanning/api';
import {
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  ResourceApiGetZonesByRegionForAccountRequest,
} from '@ariksa/inventory-core/api';
import {
  DefaultApiGetCrossAccountsForScanningRequest,
  DefaultApiUpdateScannerSettingsRequest,
  ScannerCrossAccounResponse,
  ScannerInfo,
} from '@ariksa/scan-analysis/api';
import { forEach } from 'lodash';
import { QueryAction, QueryStatus } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

export const initialState: ContainerState = {
  dataOutposts: QueryState.init([]),
  vulnerabilityOutposts: QueryState.init([]),
  outpostActions: QueryState.init([]),
  resourceDetails: {},
  resources: {},
  outpostToken: QueryState.init({}),
  zones: QueryState.init([]),
  accountsScanned: {},
};

const outpostSlice = createSlice({
  name: 'outpost',
  initialState,
  reducers: {
    /*get vulnerability scanner*/
    getVulnerabilityScanners(
      state,
      action: QueryAction<Record<string, ScannerInfo[]>>,
    ) {
      state.vulnerabilityOutposts = QueryState.next(
        state.vulnerabilityOutposts,
        action,
      );
    },

    /*get data scanners*/
    getDataScanners(
      state,
      action: QueryAction<Record<string, any>, FileDataApiGetScannersRequest>,
    ) {
      state.dataOutposts = QueryState.next(state.dataOutposts, action);
    },

    /*get resource details*/
    getResourceDetails(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2Request>,
    ) {
      const resourceType = action.payload.q.insightV2Request.resource_type;
      if (!state.resourceDetails[resourceType!]) {
        state.resourceDetails[resourceType!] = QueryState.init({} as any, {
          status: QueryStatus.pending,
        });
        return;
      } else {
        state.resourceDetails[resourceType!] = QueryState.next(
          state.resourceDetails[resourceType!],
          action,
          {
            mapData: res => {
              let data: Record<string, any> = {};
              forEach(res?.items, o => (data[o.resource_id] = o));
              return data;
            },
          },
        );
      }
    },

    generateOutpostToken(state, action: QueryAction<any>) {
      state.outpostToken = QueryState.next(state.outpostToken, action);
    },

    /*get zones*/
    getZones(
      state,
      action: QueryAction<
        string[],
        ResourceApiGetZonesByRegionForAccountRequest
      >,
    ) {
      state.zones = QueryState.next(state.zones, action);
    },

    /*rescan*/
    rescanResources(
      state,
      action: QueryAction<any, DefaultApiUpdateScannerSettingsRequest>,
    ) {
      state.outpostActions = QueryState.next(state.outpostActions, action);
    },

    /*rescan*/
    getAccountsScanned(
      state,
      action: QueryAction<
        ScannerCrossAccounResponse,
        DefaultApiGetCrossAccountsForScanningRequest
      >,
    ) {
      const id = action.payload.q.accountUuid;
      if (!state.accountsScanned[id]) {
        state.accountsScanned[id] = QueryState.init({} as any, {
          status: QueryStatus.pending,
        });
        return;
      } else {
        state.accountsScanned[id] = QueryState.next(
          state.accountsScanned[id],
          action,
        );
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = outpostSlice;
