import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import { EnvironmentRead } from '@ariksa/compliance-policies';
import { CloudInsight } from '@ariksa/inventory-core';
import {
  CloudInsightApiGetCloudInsightsRequest,
  ResourceMapping,
} from '@ariksa/inventory-core/api';
import { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { QueryAction, QueryStatus } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import {
  UserAuthServiceListRequest,
  UserAuthServiceListResponse,
} from 'api/auth/api.pb';
import { onApiCall, onApiCallError, onApiCallSuccess } from 'api/call_status';
import { apiCallStateInitialState } from 'api/initial_values';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { isTestEnv } from 'utils/env';

import {
  AppState,
  EnvironmentTypes,
  SelectedEnvironment,
  UserState,
} from './types';

const userInitTestData: UserState = {
  creating: false,
  authenticating: false,
  authenticated: true,
  loggedOut: false,
  error: null,
  tokenInfo: {},
  info: {
    id: '0000',
    username: 'test-user',
    org_id: '1111',
    org_uuid: '1111',
    email: '',
    roles: [],
  },
};

const userInitData: UserState = {
  creating: false,
  authenticating: false,
  authenticated: false,
  loggedOut: false,
  error: null,
  tokenInfo: {},
  info: {
    id: '',
    username: '',
    org_id: '',
    org_uuid: '',
    email: '',
    roles: [],
  },
};

// The initial state of the Reports container
export const initialState: AppState = {
  pathname: '/dashboard',
  organization: '',
  notifications: {
    alerts: [],
    loading: false,
    error: null,
  },
  resetPassword: {
    loading: false,
    error: null,
  },

  accessBoundary: {
    type: EnvironmentTypes.Unknown,
    id: '',
    cloudId: '',
    name: '',
    cloud: '',
    account: undefined,
    environment: undefined,
  },
  cloudAccounts: apiCallStateInitialState<any[]>([]),

  // avoid keycloak authentication flow by setting test data in user
  user: isTestEnv() ? userInitTestData : userInitData,

  userAuth: QueryState.init({
    roles: isTestEnv()
      ? [
          'cloud_onboarding::delete',
          'tenancy::delete',
          'cloud_onboarding::update',
          'tenancy::create',
          'resource::*::compliance::view',
          'resource::*::compliance::delete',
          'resource::*::policy::view',
          'tenancy::update',
          'resource::*::remediation::view',
          'resource::*::policy::delete',
          'visibility::view',
          'resource::*::remediation::update',
          'resource::*::remediation::delete',
          'resource::*::policy::create',
          'resource::*::compliance::create',
          'resource::*::remediation::create',
          'cloud_onboarding::view',
          'tenancy::view',
          'cloud_onboarding::create',
          'resource::*::policy::update',
          'resource::*::compliance::update',
        ]
      : [],
  }),

  resourceTypes: QueryState.init<ResourceMapping[]>([]),
  environments: QueryState.init<EnvironmentRead[]>([]),
  cloudInsights: QueryState.init<CloudInsight[]>([]),
  cloudInsightPage: 0,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    getUserRoles(
      state,
      action: QueryAction<
        UserAuthServiceListResponse,
        UserAuthServiceListRequest
      >,
    ) {
      state.userAuth = QueryState.next(state.userAuth, action);
    },
    updateUserAuth(state, action: PayloadAction<UserAuthServiceListResponse>) {
      state.userAuth = QueryState.init(action.payload);
    },

    loadEnvAndAccounts(state) {},

    /*Load All Accounts*/
    loadEnvironments(state, action: QueryAction<EnvironmentRead[]>) {
      state.environments = QueryState.next(state.environments, action);
    },

    /*Load All Accounts*/
    loadOnboardedCloudAccounts(state) {
      state.cloudAccounts = { ...initialState.cloudAccounts };
      onApiCall(state.cloudAccounts);
    },
    loadOnboardedCloudAccountsSuccess(
      state,
      action: PayloadAction<CloudAccountGetResponse[]>,
    ) {
      onApiCallSuccess(state.cloudAccounts);
      state.cloudAccounts.data = sortBy(action.payload, 'name');
    },
    loadOnboardedCloudAccountsError(state, action: PayloadAction<any>) {
      onApiCallError(state.cloudAccounts, action.payload);
    },

    setAccount(state, action: PayloadAction<SelectedEnvironment>) {
      // console.log(action.payload);
      if (!action.payload) state.accessBoundary = initialState.accessBoundary;
      else state.accessBoundary = action.payload;
    },
    setOrganization(state, action: PayloadAction<any>) {
      state.organization = action.payload;
      // sessionStorage.setItem('realm', action.payload);
    },
    createOrgAdmin(state, action: PayloadAction<any>) {
      state.user.creating = true;
      state.user.error = null;
    },
    createOrgAdminSuccess(state) {
      state.user.creating = false;
    },
    createOrgAdminError(state, action: PayloadAction<any>) {
      state.user.error = action.payload;
      state.user.creating = false;
    },
    refreshToken(state, action: PayloadAction<any>) {},

    setPathname(state, action: PayloadAction<any>) {
      state.pathname = action.payload.pathname;
    },
    resetPassword(state) {
      onApiCall(state.resetPassword);
    },
    resetPasswordSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.resetPassword);
    },
    resetPasswordError(state, action: PayloadAction<any>) {
      onApiCallError(state.resetPassword, action.payload);
    },
    autoLogin(state) {
      state.user.error = '';
    },

    login(state) {
      state.user.loggedOut = false;
      state.user.authenticating = true;
      state.user.error = null;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      // console.log(action.payload);
      state.user.authenticated = true;
      state.user.authenticating = false;
      state.user.loggedOut = false;
      state.user.tokenInfo = action.payload.tokenInfo;
      state.user.info = action.payload.userInfo;
    },
    loginError(state, action: PayloadAction<any>) {
      state.user.authenticating = false;
      state.user.authenticated = false;
      state.user.error = action.payload;

      console.log('======failed=======', action.payload);
    },

    // loadUserRoleById(state, action: PayloadAction<any>) { },
    // loadUserRoleByIdSuccess(state, action: PayloadAction<any>) { },
    // loadUserRoleByIdError(state, action: PayloadAction<any>) { },

    logOut(state) {},
    logOutSuccess(state) {
      state.user.authenticated = false;
      state.user.loggedOut = true;
    },
    logOutError(state, action: PayloadAction<any>) {},

    addUnreadAlerts(state, action: PayloadAction<any>) {
      state.notifications.alerts.push(action.payload);
      state.notifications.alerts = uniqBy(
        state.notifications.alerts,
        a => a.uuid,
      );
      // console.log(action.payload, state.notifications);
    },

    loadResourceTypes(state, action: QueryAction<ResourceMapping[]>) {
      state.resourceTypes = QueryState.next(state.resourceTypes, action);
    },
    getCloudInsights(
      state,
      action: QueryAction<
        CloudInsight[],
        CloudInsightApiGetCloudInsightsRequest
      >,
    ) {
      // update state with new data
      if (action.payload.status === QueryStatus.fulfilled) {
        state.cloudInsightPage = action.payload.q.page ?? 0;
        state.cloudInsights.data = sortBy(
          uniqBy(
            [...state.cloudInsights.data, ...(action.payload.data ?? [])],
            'event_time',
          ),
          r => -dayjs(r.event_time).unix(),
        );
      }

      // update status
      state.cloudInsights.status = action.payload.status!;
      state.cloudInsights.isLoading =
        action.payload.status === QueryStatus.pending;
    },
    updateCloudInsightPage(state, action: PayloadAction<number>) {
      state.cloudInsightPage = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = appSlice;
