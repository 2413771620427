import { extendTheme, theme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { LocalStoreService } from 'services/ZocalStore/local_store_service';

import { fixedColors } from './fixedColors';
import { colors } from './theme2/colors';
import { dark } from './theme2/dark';
import { light } from './theme2/light';
import { colorOpacity } from './utils';

const colorMode = LocalStoreService.getChakraColorMode();
const primaryColor = props => mode(light.primary, dark.primary)(props);

export const customTheme = extendTheme({
  ...theme,
  styles: {
    global: props => ({
      body: {
        fontSize: '15px',
        bg: props.colorMode === 'dark' ? '#141414' : colors.background,
        fontFamily: 'Rubik, Roboto, system-ui, sans-serif',
        //fontStyle: 'normal',
      },
      heading: {
        fontFamily: 'Rubik, Roboto, Georgia, serif',
      },
    }),
  },
  components: {
    Switch: {
      baseStyle: props => ({
        track: {
          background: theme.colors.gray['300'],
          borderRadius: 3,
          _checked: {
            background: primaryColor(props),
          },
        },
      }),
    },
    Progress: {
      baseStyle: props => ({
        filledTrack: {
          bg: mode(
            `${props.colorScheme}.300`,
            `${props.colorScheme}.200`,
          )(props),
        },
      }),
    },
    Checkbox: {
      baseStyle: props => ({
        control: {
          _disabled: {
            opacity: 0.3,
          },
          _focus: {
            borderColor: primaryColor(props),
            boxShadow: `0 0 0 1px ${primaryColor(props)}`,
          },
          _checked: {
            background: primaryColor(props),
            borderColor: primaryColor(props),
            _disabled: {
              opacity: 0.3,
              background: '#fff',
              borderColor: primaryColor(props),
            },
            _hover: {
              background: primaryColor(props),
              borderColor: primaryColor(props),
              boxShadow: `0 0 0 1px ${primaryColor(props)}`,
            },
          },
        },
      }),
    },
    Radio: {
      baseStyle: props => ({
        control: {
          _checked: {
            background: primaryColor(props),
            borderColor: primaryColor(props),
            _hover: {
              background: primaryColor(props),
              borderColor: primaryColor(props),
            },
          },
        },
      }),
    },
    Modal: {
      baseStyle: () => ({
        dialog: {
          top: '40px',
        },
      }),
    },
    Popover: {
      variants: {
        responsive: {
          popper: {
            maxWidth: 'unset',
            width: 'unset',
          },
        },
      },
    },
  },
  fonts: {
    ...theme.fonts,
    body: 'Rubik, Roboto, system-ui, sans-serif',
    heading: 'Rubik, Roboto, Georgia, serif', //Gilmer
  },
  config: {
    //useSystemColorMode: false,
    initialColorMode: 'light',
  },
  fontWeights: {
    xs: 300,
    sm: 400,
    md: 500,
    lg: 600,
    bold: 700,
  },
  //breakpoints: [...theme.breakpoints, '90em'],
  sizes: {
    ...theme.sizes,
    '7xl': '80rem',
    '8xl': '90rem',
    '9xl': '100rem',
    primaryNavBarHeight: '50px',
    secondaryNavBarHeight: '36px',
    breadcrumbHeight: '60px',
    totalHeaderHeight: '80px',
    layoutLeftWidth: '254px',
    formWidth: '600px',
    bodyPadding: 4,
    interCardSpacing: 4,
    dashboardCardSpacing: 14, //this is same as interCardSpacing
    minScreenWidth: '1024px',
    minUIWidth: '110rem',
    tableCell: '15px',
  },
  fontSizes: {
    ...theme.fontSizes,
    navBarHeader: '15px',
    '2xs': '10px',
    tableCell: '15px',
  },
  space: {
    ...theme.space,
    layoutLeftWidth: '254px',
    thLeftPaddingWithIcon: theme.space['12'],
  },
  radii: {
    ...theme.radii,
    fieldBorderRadius: '3px',
    cardBorderRadius: '8px',
  },
  colors: {
    ...fixedColors,
    ...colors,
    ...(colorMode === 'dark' ? dark : light),
  },
});

export const colorMap = {
  primary(op: Opacity = 500) {
    return colorOpacity(customTheme.colors.primary, op / 500);
  },
};

type Opacity = 0 | 30 | 50 | 100 | 200 | 300 | 400 | 500;

//export const customTheme = light;
