import { useMemo } from 'react';

import { CloudInsight } from '@ariksa/inventory-core/api';
import { Circle, HStack, Stack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { Heading2, renderTime, Tag } from 'components/DataDisplay';
import { Tags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/Tags';
import { selectApp } from 'containers/App/selectors';
import {
  selectCloudAccountOptions,
  selectCloudAccounts,
} from 'containers/Setup/CloudAccounts/selectors';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';

interface CloudInsightItemProps {
  item: CloudInsight;
  isLast?: boolean;
}

export const CloudInsightItem = (props: CloudInsightItemProps) => {
  const { item, isLast } = props;

  const circleColor = useMemo(() => {
    switch (item.category) {
      case 'Informational':
        return customTheme.colors.yellow['400'];
      case 'Elevated Risk':
        return customTheme.colors.red['500'];
      default:
        return customTheme.colors.red['500'];
    }
  }, [item.category]);

  const { cloudAccounts } = useSelector(selectApp);

  return (
    <HStack spacing={10} alignItems={'flex-start'}>
      <Circle size="20px" bg={circleColor} />
      <Stack>
        <HStack spacing={6}>
          <Heading2>{item.category ?? '-'}</Heading2>
          <Text>{renderTime({ value: item.event_time })}</Text>
        </HStack>
        <HStack>
          <Tag
            label={
              <CloudAccountName
                accountId={
                  cloudAccounts.data.find(
                    a => a.cloud_account_id === item.account_id,
                  )?.uuid ?? '-'
                }
              />
            }
          />
          <Text>{item.description ?? '-'}</Text>
        </HStack>
      </Stack>
    </HStack>
  );
};
