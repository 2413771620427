import React, { useState } from 'react';

import { H2, WithResourceIcon } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { CloudInsightList } from 'containers/App/CloudInsight/CloudInsightList';

interface CloudInsightDrawerProps {
  isOpen: boolean;

  onClose(): void;
}

export const CloudInsightDrawer = (props: CloudInsightDrawerProps) => {
  const { isOpen, onClose } = props;

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      styles={{ drawer: { size: 'xl' }, body: { paddingRight: 0 } }}
      closeButton
      header={
        <WithResourceIcon
          resourceType={IconTypes.BlueprintView}
          bgColor="primary"
          iconSize="medium"
        >
          <H2>RUNTIME INSIGHTS</H2>
        </WithResourceIcon>
      }
      body={<CloudInsightList />}
    />
  );
};
