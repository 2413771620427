import React, { FC, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { join } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  renderRiskContext,
  StackedHeader,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

interface Props {
  isOpen: boolean;
  onClose();
  row: any;
}

export const ECRImageContainers: FC<Props> = props => {
  const { isOpen, onClose, row } = props;
  const { ecrImageContainers } = useSelector(selectActiveResource);

  const dispatch = useDispatch();

  useEffect(() => {
    isOpen &&
      dispatch(
        actions.getECRImageContainers({
          q: { getContainersRequest: { image_names: [row?.uri] } },
        }),
      );
  }, [dispatch, isOpen, row]);

  const columns: TableColumnProps[] = [
    {
      header: <Box pl={12}>Container</Box>,
      accessor: 'container_id',
      render: ({ value }) => (
        <WithResourceIcon resourceType="Container">{value}</WithResourceIcon>
      ),
    },
    {
      header: 'Account',
      //accessor: 'severity',
      align: 'left',
    },
    {
      header: 'Deployed In',
      accessor: 'instance_id',
      align: 'left',
    },
    {
      header: 'IP Address',
      //accessor: 'severity',
      align: 'left',
    },
    {
      header: 'Container OS',
      //accessor: 'severity',
      align: 'left',
    },
    {
      header: 'Risk Context',
      render: ({ row }) => renderRiskContext([]),
      align: 'left',
    },
  ];

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      closeButton
      header={
        <Box>
          <StackedHeader
            upper={row?.name ?? join(row?.image_tags, ', ')}
            lower={row?.uri}
            type={IconTypes.EcrImage}
          />
        </Box>
      }
      body={
        <Box h={'full'}>
          <Table
            data={ecrImageContainers?.data ?? []}
            columns={columns}
            isLoading={ecrImageContainers?.isLoading}
          />
        </Box>
      }
      styles={{ content: { maxW: '1200px' } }}
    />
  );
};
