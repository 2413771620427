import React, { useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { getIcon, WithResourceIcon } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { SideNav, SideNavMenuItemProps } from 'components/Navigation';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { DataDashboard } from 'containers/Visibility/Data/Components/DataDashboard';
import { dataSideNavItemList } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { useSearchParams } from 'hooks/useSearchParams';

import { actions } from '../slice';

import { DataTables } from './DataTables';

export const DataDashboardSideNav: React.FC = () => {
  const dispatch = useDispatch();
  const [menuItems, setMenuItems] = useState<SideNavMenuItemProps[]>([]);
  const [showDataTypeSources, setShowDataTypeSources] = useState(false);
  const { contentHeight } = usePageContentContext();
  const { getResourceType } = useResourceType();
  const { selectedTab } = useSelector(selectDataDashboard);
  const params = useSearchParams<{
    tab: string;
    uuid: string;
    resource_type: string;
  }>();

  useEffect(() => {
    const items: SideNavMenuItemProps[] = map(dataSideNavItemList, o => ({
      title: o?.title,
      key: o?.key,
      icon: <Box color="primary">{getIcon(o?.iconType)}</Box>,
    }));

    setMenuItems([
      {
        title: (
          <Box>
            <WithResourceIcon resourceType={IconTypes.Overview} spacing={2}>
              OVERVIEW
            </WithResourceIcon>
          </Box>
        ),
        key: 'overview',
        component: <DataDashboard />,
      },
      {
        title: (
          <Box>
            <WithResourceIcon resourceType={IconTypes.AllFindings} spacing={2}>
              ALL
            </WithResourceIcon>
          </Box>
        ),
        key: 'all',
        component: <DataTables />,
        showDivider: true,
      },
      ...items,
    ]);
  }, [dispatch, getResourceType]);

  useEffect(() => {
    if (!!params.tab) {
      dispatch(actions.updateSelectedTab(params.tab));
    }
  }, [params.tab, dispatch]);

  return (
    <HStack spacing={4} h={contentHeight + 'px'} w="full" align="flex-start">
      <SideNav
        menuItems={menuItems}
        hasContent={false}
        navCompWidth={64}
        current={selectedTab}
        onChange={tab => {
          setShowDataTypeSources(false);
          dispatch(actions.updateSelectedTab(tab));
          dispatch(actions.updateDataTablePageInfo(INIT_PAGE_INFO));
        }}
      />
      <>
        {selectedTab === 'overview' ? (
          <DataDashboard />
        ) : (
          <DataTables
            showDataTypeSources={showDataTypeSources}
            setShowDataTypeSources={setShowDataTypeSources}
          />
        )}
      </>
    </HStack>
  );
};
