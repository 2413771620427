import React from 'react';

import { SupportedServices } from '@ariksa/data-scanning/api';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { colorOpacity, customTheme } from 'theme';

import { Tag } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { BitbucketIcon, GithubIcon, GitLabIcon } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { getIconColor } from 'components/Icons/Components/getIconColor';
import { limitedString } from 'utils/string';

export const renderResources = ({ row }) => {
  return formatNumber(row?.total_sensitive_records, 1);
};

export const getDataSourceIcon = type => {
  switch (type) {
    case SupportedServices.GitHubBranch:
      return <GithubIcon />;
    case SupportedServices.GitLabBranch:
      return <GitLabIcon />;
    case SupportedServices.BitBucketBranch:
      return <BitbucketIcon />;
    default:
      return getIcon(type);
  }
};

const renderLabel = ({ label, color, borderColor }) => (
  <Tag
    styles={{
      label: { color: 'black' },
      tag: { bg: color, fontSize: '13px', px: 1.5, borderColor },
    }}
    label={
      <CustomTooltip label={label} textAlign="left">
        <Box>{limitedString(label, 14)}</Box>
      </CustomTooltip>
    }
  />
);

export const renderLabels = value => (
  <Stack spacing={1}>
    <HStack spacing={1}>
      {value[0] && renderLabel(value[0])}
      {value[1] && renderLabel(value[1])}
    </HStack>
    <HStack spacing={1}>
      {value[2] && renderLabel(value[2])}
      {value?.length > 3 && (
        <CustomTooltip
          label={
            <Stack>
              {map(value.slice(3, value?.length), o => (
                <Box>{o?.label}</Box>
              ))}
            </Stack>
          }
        >
          <Tag
            label={'+' + (value?.length - 3)}
            styles={{
              tag: { fontSize: '13px', bg: 'primary' },
              label: { color: 'white' },
            }}
          />
        </CustomTooltip>
      )}
    </HStack>
  </Stack>
);

const getBorderColor = type => {
  switch (type?.toLowerCase()) {
    case 'pii':
      return 'primary';
    case 'phi':
      return 'skyBlue.300';
    case 'pci':
      return 'blue.300';
    default:
      return 'green.300';
  }
};

const getLabelColor = type => {
  switch (type) {
    case 'PII':
    case 'PHI':
    case 'PCI':
      return getIconColor(type);
    default:
      return colorOpacity(customTheme.colors.green['300'], 0.4);
  }
};
export const renderDataTypeLabels = ({ row }) =>
  renderLabels(
    map(
      row?.source_labels ??
        row?.labels ??
        row?.data_type_labels ??
        row?.data_labels,
      o => ({
        label: o,
        color: getLabelColor(o),
        borderColor: getBorderColor(o),
      }),
    ),
  );
