import { useCallback, useState } from 'react';

import { AccessSummaryTypes, NativeResources } from '@ariksa/inventory-core';
import { VulnerabilitySeverity } from '@ariksa/scan-analysis';
import { each, isEmpty, map, toArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { QueryStatus } from 'services/types';

import { InventoryService } from 'api/services';
import { getPublishedTime } from 'components/DataEntry';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { ActiveResourceInfo } from 'containers/ActiveCloudResource/types';

export function useActiveResourceActions() {
  const dispatch = useDispatch();
  const {
    resourceUuid,
    resourceType,
    resourceAccountId,
    resourceId,
    resourceInsight,
    vulnerabilityPublishedTime,
    showResolvedVulnerabilities,
    isPatchable,
    vulnerabilitySeverity,
    alerts,
    amiHosts,
  } = useSelector(selectActiveResource);
  const { hosts, vulnerabilities, riskContext } = amiHosts;

  const [params, setParams] = useState<ActiveResourceInfo>({
    uuid: resourceUuid,
    resourceType: resourceType as NativeResources,
    accountId: resourceAccountId,
    resourceId: resourceId,
  });

  // useEffect(() => {
  //   setParams({ resourceUuid, resourceType, resourceAccountId });
  // }, [resourceUuid, resourceType, resourceAccountId]);

  const updateActiveResource = useCallback(
    (resourceInfo: ActiveResourceInfo) => {
      setParams(resourceInfo);
      dispatch(actions.updateResource(resourceInfo));
    },
    [dispatch],
  );

  const isNoOp = useCallback(() => {
    return resourceUuid === '';
  }, [resourceUuid]);

  const getResourceDetails = useCallback(() => {
    if (isNoOp()) return;
    dispatch(
      actions.getResourceDetails({
        q: {
          resourceUuid,
          resourceType: resourceType as any,
          account: resourceAccountId,
        },
      }),
    );
  }, [dispatch, isNoOp, resourceAccountId, resourceType, resourceUuid]);

  const getAMIHosts = useCallback(() => {
    if (!resourceId) return;
    dispatch(
      actions.getAMIHosts({
        q: {
          vMByAMIRequest: {
            account_id: !resourceAccountId ? [resourceAccountId] : [],
            ami: resourceId,
            page: hosts.page.info.page_number,
            size: hosts.page.info.page_size,
          },
        },
        onSuccess: res => {
          dispatch(
            actions.getAMIHostsRiskContext({
              q: {
                riskContextRequest: { uuids: map(res?.items, o => o?.uuid) },
              },
            }),
          );

          const resources: Record<string, Record<string, any>> = {};
          each(res?.items, o =>
            !resources[o.native_name]
              ? (resources[o.native_name] = { [o.uuid]: o })
              : (resources[o.native_name] = {
                  ...resources?.[o.native_name],
                  [o.uuid]: o,
                }),
          );
          if (!isEmpty(resources)) {
            each(resources, (o, key) => {
              const uuids = map(o, u => u?.uuid);
              const accounts = map(o, u => u?.account);
              const payload = {
                resourceVulnerabilitiesRequest: {
                  native_resource: key as NativeResources,
                  resource_ids: uuids,
                  account_ids: accounts,
                },
              };
              dispatch(
                actions.getAMIHostsVulnerabilities({
                  q: payload,
                  status: QueryStatus.pending,
                }),
              );
              InventoryService.Vulnerability.getVulnerabilities(payload).then(
                res => {
                  dispatch(
                    actions.getAMIHostsVulnerabilities({
                      q: payload,
                      status: QueryStatus.fulfilled,
                      data: res.data,
                    }),
                  );
                },
              );
            });
          }
        },
      }),
    );
  }, [hosts.page.info, dispatch, resourceAccountId, resourceId]);

  const getGcpResourceDetails = useCallback(() => {
    if (isNoOp()) return;
    dispatch(
      actions.getGcpResourceDetails({
        q: {
          uuid: resourceUuid,
          resourceType: resourceType as any,
          accountId: resourceAccountId,
        },
      }),
    );
  }, [dispatch, isNoOp, resourceAccountId, resourceType, resourceUuid]);

  const getResourceRiskContext = useCallback(() => {
    if (isNoOp()) return;
    dispatch(
      actions.getRiskContext({
        q: {
          riskContextRequest: {
            uuids: [resourceUuid],
          },
        },
      }),
    );
  }, [dispatch, isNoOp, resourceUuid]);

  const getPermissions = useCallback(() => {
    if (isNoOp()) return;
    !!resourceInsight.data?.arn &&
      dispatch(
        actions.getPermissions({
          q: {
            resourceUuid: resourceUuid,
            accountId: resourceAccountId,
            region: resourceInsight.data?.region,
          },
        }),
      );
  }, [dispatch, isNoOp, resourceAccountId, resourceInsight.data, resourceUuid]);

  const getResourceInsight = useCallback(() => {
    if (isNoOp()) return;
    dispatch(
      actions.getResourceInsight({
        q: {
          insightV2Request: {
            resource_type: resourceType as NativeResources,
            resource_uuids: !!resourceUuid ? [resourceUuid] : [],
            resource_ids: !!resourceId ? [resourceId] : [],
            account_id: [resourceAccountId],
          },
        },
      }),
    );
  }, [
    dispatch,
    isNoOp,
    resourceUuid,
    resourceType,
    resourceAccountId,
    resourceId,
  ]);

  const getNetworkMap = useCallback(() => {
    if (isNoOp()) return;
    dispatch(
      actions.getNetworkMap({
        q: {
          nativeResource: resourceType,
          searchQuery: { uuid: resourceUuid, account_id: [resourceAccountId] },
        },
      }),
    );
  }, [dispatch, isNoOp, resourceAccountId, resourceType, resourceUuid]);

  const getEntityTimeline = useCallback(() => {
    if (isNoOp()) return;
    dispatch(
      actions.getEntityTimeline({
        q: { accountId: resourceAccountId, entityUuid: resourceUuid },
      }),
    );
  }, [dispatch, isNoOp, resourceAccountId, resourceUuid]);

  const getEntitySeverity = useCallback(() => {
    if (isNoOp()) return;
    dispatch(
      actions.getEntitySeverity({
        q: { requestBody: [resourceUuid] },
      }),
    );
  }, [dispatch, resourceUuid, isNoOp]);

  // cache pageInfo to avoid loops
  const getAlerts = useCallback(() => {
    if (isNoOp()) return;
    dispatch(
      actions.getAlerts({
        q: {
          entityUuid: params.uuid,
          resource: params.resourceType,
          pageSize: alerts.page.info.page_size,
          pageNumber: alerts.page.info.page_number,
        },
        onSuccess: res => {
          !isEmpty(res) &&
            dispatch(
              actions.getAlertStatus({
                q: { alertId: map(res?.results, o => o?.uuid!) },
              }),
            );
        },
      }),
    );
  }, [dispatch, isNoOp, params, alerts.page.info]);

  const getVulnerabilitySummary = useCallback(() => {
    if (
      resourceType !== NativeResources.Ec2Instance &&
      resourceType !== NativeResources.LambdaFunction &&
      resourceType !== NativeResources.Container &&
      resourceType !== NativeResources.AmazonMachineImage &&
      resourceType !== NativeResources.GcpComputeEngine &&
      resourceType !== NativeResources.GcpCloudFunctions &&
      resourceType !== NativeResources.GcpContainer
    )
      return;

    let id: Record<string, any> = {
      instanceId: encodeURIComponent(resourceId),
    };

    if (
      resourceType === NativeResources.Container ||
      resourceType === NativeResources.GcpContainer
    ) {
      id = { containerId: encodeURIComponent(resourceId) };
    }

    const published = getPublishedTime(vulnerabilityPublishedTime.value);
    const publishedTime = showResolvedVulnerabilities
      ? { resolvedAt: published }
      : { publishedAt: published };
    const patchable =
      isPatchable === 'yes' ? true : isPatchable === 'no' ? false : undefined;
    const severityFilter = !!vulnerabilitySeverity
      ? { severity: vulnerabilitySeverity as VulnerabilitySeverity }
      : undefined;
    const payload = {
      resolved: showResolvedVulnerabilities,
      isPatchable: patchable,
      ...publishedTime,
      ...severityFilter,
    };
    if (resourceType === NativeResources.AmazonMachineImage) {
      !!resourceUuid &&
        dispatch(
          actions.getAMIVulnerabilitySummary({
            q: {
              resourceId: resourceUuid,
              nativeResource: NativeResources.AmazonMachineImage,
              ...payload,
            },
          }),
        );
    } else {
      !!resourceId &&
        dispatch(
          actions.getVulnerabilitySummary({
            q: {
              ...id,
              ...payload,
            },
          }),
        );
    }
  }, [
    dispatch,
    resourceType,
    resourceId,
    vulnerabilityPublishedTime,
    showResolvedVulnerabilities,
    isPatchable,
    vulnerabilitySeverity,
    resourceUuid,
  ]);

  const getCanAccessSummary = useCallback(
    (summaryType: AccessSummaryTypes) => {
      if (isNoOp()) return;
      dispatch(
        actions.getCanAccessSummary({
          q: {
            accountId: resourceAccountId,
            uuid: resourceUuid,
            nativeResourceType: resourceType,
            summaryType,
          },
        }),
      );
    },
    [dispatch, isNoOp, resourceAccountId, resourceUuid, resourceType],
  );

  const getPrivilegeSummary = useCallback(() => {
    if (isNoOp()) return;
    dispatch(
      actions.getPrivilegeSummary({
        q: {
          accountId: resourceAccountId,
          uuid: resourceUuid,
          nativeResourceType: resourceType,
        },
      }),
    );
  }, [dispatch, isNoOp, resourceAccountId, resourceUuid, resourceType]);

  const getAccessMapData = useCallback(
    (expanded: boolean = false) => {
      if (isNoOp()) return;

      const resourcesWithAccessMap: NativeResources[] = [
        NativeResources.AccountRoot,
        NativeResources.IamUser,
        NativeResources.IamGroup,
        NativeResources.IamRole,
        NativeResources.IamPolicy,
        NativeResources.Ec2Instance,
        NativeResources.ElasticContainerRegistry,
        NativeResources.ElasticKubernetesService,
        NativeResources.ElasticContainerService,
        NativeResources.EcsCluster,
        NativeResources.OktaUser,
        NativeResources.AzureAdUser,
        NativeResources.SnowflakeUsers,
        NativeResources.SnowflakeTables,
        NativeResources.SnowflakeDatabases,
        NativeResources.SnowflakeRoles,
        NativeResources.SnowflakeSchemas,
      ];

      //console.log(resourceType, resourcesWithAccessMap.includes(resourceType));

      if (resourcesWithAccessMap.includes(resourceType)) {
        resourceAccountId &&
          dispatch(
            actions.getAccessMapData({
              q: {
                accountId: resourceAccountId,
                uuid: resourceUuid,
                nativeResourceType: resourceType,
                expanded: expanded,
              },
            }),
          );
      }
    },
    [dispatch, isNoOp, resourceAccountId, resourceUuid, resourceType],
  );

  return {
    updateActiveResource,
    getResourceDetails,
    getResourceInsight,
    getAlerts,
    getEntityTimeline,
    getVulnerabilitySummary,
    getEntitySeverity,
    getCanAccessSummary,
    getAccessMapData,
    getPermissions,
    getNetworkMap,
    getPrivilegeSummary,
    getGcpResourceDetails,
    getResourceRiskContext,
    getAMIHosts,
  };
}
