/* --- STATE --- */

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import { EnvironmentRead } from '@ariksa/compliance-policies';
import { CloudInsight } from '@ariksa/inventory-core';
import { ResourceMapping } from '@ariksa/inventory-core/api';
import { IQueryState } from 'services/utils/QueryState';

import { AlertData } from 'api/alerts/types';
import { UserAuthServiceListResponse } from 'api/auth/api.pb';
import { ApiCallState, ApiCallStatus } from 'api/types';

export interface AppState {
  pathname: string;
  organization: string;
  notifications: ApiCallStatus & {
    alerts: AlertData[];
  };
  cloudAccounts: ApiCallState<CloudAccountGetResponse[]>;
  accessBoundary: SelectedEnvironment;
  user: UserState;
  resetPassword: ApiCallStatus;
  userAuth: IQueryState<UserAuthServiceListResponse>;
  resourceTypes: IQueryState<ResourceMapping[]>;
  environments: IQueryState<EnvironmentRead[]>;
  cloudInsights: IQueryState<CloudInsight[]>;
  cloudInsightPage: number;
}

export interface CloudAccount extends Record<string, any> {
  // name: string;
}

export interface UserState {
  creating: boolean;
  authenticating: boolean;
  authenticated: boolean;
  loggedOut: boolean;
  tokenInfo: any;
  info: UserInfo;
  error: any;
}

interface UserInfo extends Record<string, any> {
  id: string;
  username: string;
  org_id: string;
  email: string;
  org_uuid: string;
  avatar?: string;
  account_ids?: string[];
}

export interface ResourceTypes extends ApiCallStatus {
  data: string[];
}

export type ContainerState = AppState;

export interface SelectedEnvironment {
  type: EnvironmentTypes;
  id?: string;
  cloudId?: string;
  name?: string;
  cloud?: string;
  environmentId?: string;

  account?: CloudAccount;
  environment?: EnvironmentRead;
}

export enum EnvironmentTypes {
  Unknown = 'Unknown',
  Account = 'Account',
  Environment = 'Environment',
  Snowflake = 'Snowflake',
  Octa = 'Octa',
}

export interface Environment {
  environment?: EnvironmentRead;
  accounts: CloudAccount[];
}
