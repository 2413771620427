import React, { FC } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from 'components/DataEntry/Button';
import { contentStyles } from 'components/FeatureDemo/styles';
import {
  AnomalousBehaviourIcon,
  AwsIcon,
  AzureIcon,
  CompanyLogoIcon,
  DatabaseIcon,
  GlobeIcon,
  GoogleCloudIcon,
  UserIcon,
} from 'components/Icons';
import { getIcon } from 'components/Icons/Components';

import { customTheme } from '../../../../theme';

export const Onboard: FC = () => {
  const navigate = useNavigate();
  const list = [
    {
      link: '/setup/accounts/add/aws',
      key: CloudProviders.Aws,
      icon: <AwsIcon pt={2} />,
    },
    {
      link: '/setup/accounts/add/gcp',
      key: CloudProviders.Gcp,
      icon: <GoogleCloudIcon />,
    },
    {
      link: '/setup/accounts/add/azure',
      key: CloudProviders.Azure,
      icon: <AzureIcon />,
    },
  ];

  return (
    <Stack {...contentStyles.root}>
      <Flex {...contentStyles.logoWrapper}>
        {/* Logo */}
        <Center my="auto" {...contentStyles.logo}>
          <CompanyLogoIcon />
        </Center>
      </Flex>
      <Box fontWeight={600} fontSize="xl" pl={6}>
        Onboard Cloud Accounts
      </Box>
      <Stack {...contentStyles.content}>
        <HStack spacing={24} pt={12}>
          {map(list, (o, index) => (
            <Box
              boxSize={40}
              border="1px solid"
              borderColor="primary"
              borderRadius={6}
              bg="white"
              overflow="visible"
              p={12}
              boxShadow={`1px 6px 6px ${customTheme.colors.gray['100']}`}
              onClick={() => navigate(o.link)}
              key={index + '-key-feature-demo'}
              cursor="pointer"
            >
              <Center>{o.icon}</Center>
            </Box>
          ))}
        </HStack>
      </Stack>
    </Stack>
  );
};
