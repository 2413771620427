import {
  CloudAccountApiGetCloudAccountsRequest,
  CloudAccountApiOnboardCloudAccountRequest,
  CloudAccountApiUpdateCloudAccountRequest,
  CodeRepositoryApiIsTokenValidRequest,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import {
  Commands,
  ConfigApiGetCommandsConfigRequest,
  ConfigApiGetOutpostConfigRequest,
  ConfigData,
} from '@ariksa/inventory-core/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the SetupUsers container
export const initialState: ContainerState = {
  onboardAccount: QueryState.init({}),
  validateToken: QueryState.init(false),
  accounts: PagedQueryState.init([]),
  configCommands: PagedQueryState.init({} as Commands),
  outpostConfig: QueryState.init({}),
};

const setupSlice = createSlice({
  name: 'setup',
  initialState,
  reducers: {
    //Identity And Access Providers and saas accounts
    onboardAccount(
      state,
      action: QueryAction<any, CloudAccountApiOnboardCloudAccountRequest>,
    ) {
      state.onboardAccount = QueryState.next(state.onboardAccount, action);
    },

    //validate repository access token
    validateToken(
      state,
      action: QueryAction<boolean, CodeRepositoryApiIsTokenValidRequest>,
    ) {
      state.validateToken = QueryState.next(state.validateToken, action);
    },

    /*Load All Accounts*/
    getAccounts(
      state,
      action: QueryAction<
        PageCloudAccountGetResponse,
        CloudAccountApiGetCloudAccountsRequest
      >,
    ) {
      state.accounts = PagedQueryState.next(state.accounts, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total || 0,
      });
    },
    updateAccountsPageInfo(state, action: PayloadAction<PageInfo>) {
      state.accounts.page.info = action.payload;
    },

    /*Update Account*/
    updateCloudAccount(
      state,
      action: QueryAction<any, CloudAccountApiUpdateCloudAccountRequest>,
    ) {
      state.onboardAccount = QueryState.next(state.onboardAccount, action);
    },

    getConfigCommands(
      state,
      action: QueryAction<Commands, ConfigApiGetCommandsConfigRequest>,
    ) {
      state.configCommands = QueryState.next(state.configCommands, action);
    },

    getOutpostConfig(
      state,
      action: QueryAction<ConfigData, ConfigApiGetOutpostConfigRequest>,
    ) {
      state.outpostConfig = QueryState.next(state.outpostConfig, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = setupSlice;
