import React, { useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Stack, Text } from '@chakra-ui/react';
import { map, sum, values } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { CriticalDataIcon } from 'components/Icons';
import { getIconColor } from 'components/Icons/Components/getIconColor';
import { CustomTooltip } from 'components/Visualization/Charts/CustomTooltip';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { DataSourcesCardWithBarChart } from 'containers/Dashboard/utils/DataSourcesCardWithBarChart';

export const DataSourcesAndSensitiveData: React.FC = () => {
  const { sensitiveDataBySources } = useSelector(selectDataSecurityOverview);
  const { summaryOfAllDataSources } = useSelector(selectDashboard);
  const { getResourceAlias } = useResourceType();
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const sensitiveData = sensitiveDataBySources.data;
    const dataSources = summaryOfAllDataSources.data?.resource_type_counts;
    setChartData([
      ...(!dataSources?.Buckets
        ? []
        : [
            {
              name: 'Buckets',
              color: customTheme.colors.primary,
              'Number of Data Sources': dataSources?.Buckets,
              'Sensitive data count': formatNumber(sensitiveData?.Buckets ?? 0),
            },
          ]),
      ...(!dataSources?.Databases
        ? []
        : [
            {
              name: 'Databases',
              color: getIconColor(NativeResources.RelationalDatabaseService),
              'Number of Data Sources': dataSources?.Databases,
              'Sensitive data count': formatNumber(sensitiveData?.DB ?? 0),
            },
          ]),
      ...(!dataSources?.Others
        ? []
        : [
            {
              name: 'Disks',
              color: customTheme.colors.skyBlue['300'],
              'Number of Data Sources': dataSources?.Others,
              'Sensitive data count': formatNumber(sensitiveData?.Others ?? 0),
            },
          ]),
      ...(!dataSources?.SaaS
        ? []
        : [
            {
              name: 'SaaS',
              color: customTheme.colors.orange['400'],
              'Number of Data Sources': dataSources?.SaaS,
              'Sensitive data count': formatNumber(sensitiveData?.SaaS ?? 0),
            },
          ]),
    ]);
  }, [
    summaryOfAllDataSources.data,
    sensitiveDataBySources.data,
    getResourceAlias,
  ]);

  const customTooltipContent = props => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const { payload: obj } = payload[0] ?? {};

      return (
        <>
          <CustomTooltip>
            <Stack fontSize={'sm'}>
              <Text as="b">{obj.name}</Text>
              <Text>
                Number of data sources:{' '}
                <Text as="b">{obj['Number of Data Sources']}</Text>
              </Text>
              <Text>
                Sensitive data count:{' '}
                <Text as="b">{obj['Sensitive data count']}</Text>
              </Text>
            </Stack>
          </CustomTooltip>
        </>
      );
    }

    return null;
  };

  return (
    <DataSourcesCardWithBarChart
      label="Data sources and sensitive data"
      icon={<CriticalDataIcon />}
      chartData={chartData}
      xField="name"
      yFields={['Number of Data Sources']}
      barChartColors={map(chartData, o => o.color)}
      isLoadingChart={summaryOfAllDataSources.isLoading}
      labelListKey="Sensitive data count"
      onClick={(e, k, v) => {
        let tabName = 'Data';
        switch (v?.name) {
          case 'Buckets':
            tabName = '_ObjectStorage';
            break;
          case 'Databases':
            tabName = '_Database';
            break;
        }
        if (!!parseInt(v?.['Sensitive data count']))
          navigate('/visibility/data?tab=DataSources');
        else navigate('/inventory/summary?tab=' + tabName);
      }}
      customTooltipContent={customTooltipContent}
      isLoadingSensitiveData={sensitiveDataBySources.isLoading}
      totalSensitiveData={sum(values(sensitiveDataBySources.data))}
      /*tooltip={{
          tooltip: (
            <Stack>
              <Box>
                Ariksa Outpost for Data discovers and classifies sensitive data
                in your environment continuously.
              </Box>
              <Box>
                Deploy Outpost for Data in the region of your data sources and
                add data sources to scan
              </Box>
            </Stack>
          ),
          header: 'Discover your sensitive data…',
          footerText: 'Take me there',
          onClickFooter: () => navigate('/setup/outpost'),
        }}*/
      iconBgColor={getIconColor(NativeResources.ElasticBlockStorage)}
    />
  );
};
