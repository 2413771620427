import React, { FC, useEffect, useReducer } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { DataTypeForES } from '@ariksa/reporting';
import {
  Box,
  Center,
  Divider,
  HStack,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { each, map, sortBy, sum, values } from 'lodash';
import round from 'lodash/round';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import {
  formatNumberWithComma,
  formatTooltip,
  getIcon,
  getProviderName,
  PageHeaderWithIcon,
} from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import {
  defaultSelectStyles,
  PrimaryIconButton,
  RadioButtonGroup,
  Select,
} from 'components/DataEntry';
import {
  GraphDecreaseIcon,
  GraphIncreaseIcon,
  InformationIcon,
  MinusIcon,
  PieChartIcon,
} from 'components/Icons';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { RegionAndVPCFilter } from 'containers/Inventory/CloudInventory/Components/Overview/RegionAndVPCFilter';
import {
  cloudProviderOptions,
  groupByOptions,
  sortByOptions,
} from 'containers/Inventory/CloudInventory/Components/Overview/utils';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { useInventory } from 'containers/Inventory/CloudInventory/hooks/useInventory';
import { selectInventory } from 'containers/Inventory/CloudInventory/selector';
import { actions } from 'containers/Inventory/CloudInventory/slice';
import { ExportAlerts } from 'containers/SharedState/Components/ExportAlerts/ExportAlerts';
import { selectSharedState } from 'containers/SharedState/selectors';

interface Props {}

export const Overview: FC<Props> = props => {
  const { inventoryOverview, inventoryHistory } = useSelector(selectInventory);
  const { searchTerm } = useSelector(selectSharedState);
  const {
    setShowInventorySummary,
    sideNavItemsMapping,
    selectedRegion,
    selectedVpc,
  } = useInventoryContext();
  const {
    getResourceTypeOptionsWithAlias,
    getCloudAgnosticName,
    getCloudNativeName,
  } = useResourceType();
  const dispatch = useDispatch();
  const { environmentId, accountId } = useAccessBoundary();
  const { environmentMapping } = useEnvironmentOptions();
  const { getVpcs } = useInventory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      resourceType: { label: 'All resources', value: undefined },
      resourceTypeOptions: [],
      groupBy: groupByOptions[0],
      cloud: cloudProviderOptions[0],
      categories: {},
      sortBy: sortByOptions[1]?.value,
      mouseOverItem: undefined,
      isFlipped: false,
      currentKey: '',
    },
  );

  useEffect(() => {
    getVpcs();
  }, [getVpcs]);

  useEffect(() => {
    if (!environmentId && !accountId) return;
    dispatch(
      actions.getInventoryOverview({
        q: {
          environmentId,
          accountId: !accountId ? undefined : [accountId],
          region: selectedRegion?.value,
          nativeResource: state.resourceType?.value,
          searchTerm: searchTerm ?? undefined,
          vpc: selectedVpc?.value ? [selectedVpc?.value] : undefined,
        },
      }),
    );
    dispatch(
      actions.getInventoryHistory({
        q: {
          environmentId: environmentId!,
          accountId: !!accountId ? [accountId] : undefined,
          startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        },
      }),
    );
  }, [
    dispatch,
    environmentId,
    accountId,
    selectedRegion,
    selectedVpc,
    state.resourceType,
    searchTerm,
  ]);

  //set resource type options
  useEffect(() => {
    const options = [
      { label: 'All resources', value: undefined },
      ...getResourceTypeOptionsWithAlias(),
    ];
    updateState({
      resourceTypeOptions: options,
    });
  }, [getResourceTypeOptionsWithAlias]);

  useEffect(() => {
    //sort all the resources
    let sortedResources = sortBy(inventoryOverview.data, [
      state.sortBy === 'Count' ? 'total_resources' : 'resource_type',
    ]);

    //create category: resources mapping
    let categories: Record<string, any> = {};
    if (!state.groupBy.value) {
      categories['All'] = sortedResources;
      categories['All'].total_entities = sum(
        map(sortedResources, o => o.total_resources),
      );
    } else {
      each(sortedResources, o => {
        if (!!categories[o[state.groupBy.value]])
          categories[o[state.groupBy.value]].push(o);
        else {
          categories[o[state.groupBy.value]] = [o];
        }
      });
    }

    //store all resources under each category
    let items: Record<string, any> = {};
    each(categories, (o, key) => {
      items[key] = {
        resources: o,
        key,
        total_entities: sum(map(o, r => r.total_resources)),
      };
    });

    //sort each category
    let sortedCategories = sortBy(values(items), ['key']);

    //filter by cloud
    if (!!state.cloud?.value) {
      sortedCategories = !!items[state.cloud?.value]
        ? [items[state.cloud?.value]]
        : [];
    }

    updateState({ categories: sortedCategories });
  }, [inventoryOverview.data, state.groupBy, state.sortBy, state.cloud]);

  const styles = {
    menu: provided => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      //right: 0,
    }),
    valueContainer: (styles, provided) => ({
      ...defaultSelectStyles().valueContainer(styles, provided),
    }),
  };

  const getIconColor = resource => {
    switch (resource) {
      case NativeResources.OktaGroup:
      case NativeResources.OktaUser:
        return 'orange';
      default:
        return 'primary';
    }
  };

  const renderChangeIcon = change => (
    <Center w="full" h="full">
      {change === '-' || change === 0 ? (
        <Center boxSize={2}>
          <MinusIcon />
        </Center>
      ) : change > 0 ? (
        <GraphIncreaseIcon color="red.300" />
      ) : (
        <GraphDecreaseIcon color="green.300" />
      )}
    </Center>
  );

  const renderSmallCard = item => {
    const key = item.native_resource + '_' + item.category;
    const resourceCount =
      inventoryHistory.data?.[item.native_resource]?.resource_count;
    let percentChange: number | string = '-';
    if (!!resourceCount)
      percentChange =
        resourceCount?.percentage_change === '-'
          ? '-'
          : round(resourceCount?.percentage_change, 0);
    return (
      <CustomTooltip
        label={formatTooltip({
          header: getCloudAgnosticName(item.native_resource),
          content: (
            <Stack spacing={0} w="full">
              <Box>Category: {item.category}</Box>
              <Box>
                Cloud: {getProviderName(item.cloud_provider?.toLowerCase())}
              </Box>
              <Box>
                Cloud Native: {getCloudNativeName(item.native_resource)}
              </Box>
              <Box>
                Business Critical:{' '}
                {environmentMapping?.[environmentId!]?.is_business_critical
                  ? 'Yes'
                  : 'No'}
              </Box>
              <Box>
                Change:{' '}
                {percentChange === '-'
                  ? 'No change'
                  : percentChange +
                    '% ' +
                    (percentChange > 0 ? 'increase' : 'decrease')}{' '}
                in last 7 days
              </Box>
            </Stack>
          ),
          width: 64,
        })}
        placement="right"
      >
        <Box
          border="1px solid"
          borderRadius={6}
          borderColor="primary"
          w="125px"
          py={2}
          px={2}
          bg="white"
          zIndex={800}
          h="130px"
        >
          <Stack justify="space-between" h="full">
            <HStack justify="space-between">
              <Box>
                <Center boxSize={4} color={getIconColor(item.native_resource)}>
                  {getIcon(item.native_resource)}
                </Center>
              </Box>
              <Box>
                <Center boxSize={4} pt={item.cloud_provider === 'AWS' ? 1 : 0}>
                  {getIcon(item.cloud_provider?.toLowerCase())}
                </Center>
              </Box>
              <Box
                onClick={e => {
                  e.stopPropagation();
                  handleClick(key);
                }}
              >
                <Center boxSize={4}>
                  <InformationIcon
                    color={colorOpacity(customTheme.colors.primary, 0.3)}
                  />
                </Center>
              </Box>
            </HStack>
            <Box w="full">
              <Center fontWeight={600} textAlign="center">
                {getCloudAgnosticName(item.resource_type)}
              </Center>
            </Box>
            <HStack justify="center">
              <Center fontSize="md" fontWeight={600} w="full">
                {formatNumber(item.total_resources, 1)}
              </Center>
              <Box boxSize={4} position="absolute" right={2}>
                {inventoryHistory.isLoading ? (
                  <CustomSpinner />
                ) : (
                  renderChangeIcon(percentChange)
                )}
              </Box>
            </HStack>
          </Stack>
        </Box>
      </CustomTooltip>
    );
  };

  const renderDetailedCard = item => {
    const resourceCount =
      inventoryHistory.data?.[item.native_resource]?.resource_count;
    let percentChange: number | string = '-';
    if (!!resourceCount)
      percentChange =
        resourceCount?.percentage_change === '-'
          ? '-'
          : round(resourceCount?.percentage_change, 0);
    return (
      <Box
        position="absolute"
        border="1px solid"
        borderRadius={6}
        borderColor="primary"
        w={48}
        py={2}
        px={2}
        bg="white"
        zIndex={900}
        h={48}
      >
        <Stack justify="space-between" h="full">
          <HStack w="full" justify="space-between">
            <Box boxSize={4}>
              <Center color={getIconColor(item.native_resource)}>
                {getIcon(item.native_resource)}
              </Center>
            </Box>
            <Box pl={5}>
              <Center boxSize={4} pt={item.cloud_provider === 'AWS' ? 1 : 0}>
                {getIcon(item.cloud_provider?.toLowerCase())}
              </Center>
            </Box>
            <CustomTooltip
              label={
                percentChange === 0 || percentChange === '-'
                  ? 0
                  : percentChange + '% over last 7 days'
              }
              w={24}
              textAlign="center"
            >
              <HStack
                spacing={0}
                fontSize="11px"
                color={
                  percentChange === 0 || percentChange === '-'
                    ? 'primary'
                    : percentChange > 0
                    ? 'red.300'
                    : 'green.300'
                }
              >
                <Box>
                  {percentChange === 0 || percentChange === '-'
                    ? 0
                    : percentChange > 0
                    ? '+' + percentChange
                    : percentChange}
                  %
                </Box>
                <Box boxSize={4}>{renderChangeIcon(percentChange)}</Box>
              </HStack>
            </CustomTooltip>
          </HStack>
          <Center fontWeight={600}>{item.total_resources}</Center>
          <Center fontWeight={600} textAlign="center">
            {getCloudNativeName(item.native_resource)}
          </Center>
          <Stack spacing={0}>
            <Center>
              {item?.accounts?.length ?? 0} account
              {item?.accounts?.length === 1 ? '' : 's'}
            </Center>
            <Center>
              {item?.regions?.length ?? 0} region
              {item?.regions?.length === 1 ? '' : 's'}
            </Center>
          </Stack>
        </Stack>
      </Box>
    );
  };

  const variants = {
    visible: { transform: 'rotateY(180deg)' },
    hidden: { transform: 'rotateY(0deg)' },
  };

  const variants2 = {
    visible: { transform: 'rotateY(-180deg)' },
    hidden: { transform: 'rotateY(0deg)' },
  };

  const handleClick = (key?: string) => {
    updateState({ isFlipped: !state.isFlipped, currentKey: key });
  };

  const renderCategoryCount = item => {
    const key = item.native_resource + '_' + item.category;
    return (
      <Box
        position="relative"
        style={{ transformStyle: 'preserve-3d' }}
        h="full"
        cursor="pointer"
        onClick={() => {
          const menuItem = sideNavItemsMapping[item.category];
          dispatch(actions.setNativeResource(item.native_resource));
          dispatch(actions.updateCurrentCategory(item.category as string));
          dispatch(actions.updateCurrentCategoryTitle(menuItem?.data?.name));
        }}
        zIndex={state.currentKey !== key ? 800 : 900}
      >
        <motion.div
          variants={variants}
          animate={state.currentKey !== key ? 'hidden' : 'visible'}
          transition={{ duration: 0.5 }}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
          }}
        >
          {renderSmallCard(item)}
        </motion.div>
        <motion.div
          variants={variants2}
          animate={state.currentKey === key ? 'hidden' : 'visible'}
          transition={{ duration: 0.5 }}
          style={{
            position: 'absolute',
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
            width: '100%',
            height: '100%',
            top: 0,
            //top: '-25%',
            //left: '-25%',
          }}
          onMouseOver={() => handleClick(key)}
          onMouseOut={() => handleClick()}
          onClick={() => handleClick(key)}
        >
          {renderDetailedCard(item)}
        </motion.div>
      </Box>
    );
  };

  const renderCategory = item => {
    return (
      <Stack
        border="1px solid"
        borderRadius={6}
        borderColor="gray.200"
        w="full"
        py={2}
        px={4}
        bg="selectedMenuBg"
      >
        <HStack w="full" justify="space-between">
          <PageHeaderWithIcon
            label={item.key}
            icon={getIcon(
              state.groupBy?.value === 'cloud_provider'
                ? item.key?.toLowerCase()
                : item.key,
            )}
            fontSize="sm"
            iconSize="regular"
            reversed
            border
            iconBgColor="white"
            useCustomColor
            iconColor="primary"
          />
          <Box color="gray.300" pr={5}>
            {formatNumberWithComma(item.total_entities)}{' '}
            {item.total_entities === 1 ? 'entity' : 'entities'}
          </Box>
        </HStack>
        <Divider color="gray.300" w="full" />
        <Wrap py={4} pr={4} spacing={3}>
          {map(item.resources, (o, index) => (
            <WrapItem key={index + '-category-count-inventory-overview'}>
              <Center>{renderCategoryCount(o)}</Center>
            </WrapItem>
          ))}
        </Wrap>
      </Stack>
    );
  };

  return (
    <Stack h="full" spacing={5}>
      <HStack w="full">
        <HStack w="lg">
          <Box color="primary" w={16} whiteSpace="nowrap">
            Sort by:
          </Box>
          <RadioButtonGroup
            options={sortByOptions}
            onChange={val =>
              updateState({
                sortBy: state.sortBy === val?.value ? undefined : val?.value,
              })
            }
            value={state.sortBy}
            styles={{ root: { isInline: true, w: 'full' } }}
          />
        </HStack>
        <HStack justify="flex-end" w="full">
          <Box color="primary">Filters: </Box>
          <Box w="140px" zIndex={900}>
            <Select
              options={cloudProviderOptions}
              value={state.cloud}
              onChange={s => updateState({ cloud: s })}
              showIconInValueContainer
              styles={styles}
            />
          </Box>
          <RegionAndVPCFilter />
          <Box w={48} zIndex={900}>
            <Select
              options={state.resourceTypeOptions}
              value={state.resourceType}
              onChange={s => updateState({ resourceType: s })}
              showIconInValueContainer
              styles={styles}
            />
          </Box>
          <Box w={48} zIndex={900}>
            <Select
              options={groupByOptions}
              value={state.groupBy}
              onChange={s => updateState({ groupBy: s })}
              showIconInValueContainer
              styles={styles}
            />
          </Box>
          <ExportAlerts
            type={DataTypeForES.Resources}
            tooltip="Export inventory"
          />
          <PrimaryIconButton
            aria-label="summary"
            tooltip="Show Cloud Summary"
            onClick={() => setShowInventorySummary(true)}
          >
            <PieChartIcon />
          </PrimaryIconButton>
        </HStack>
      </HStack>

      <Box w="full" h="full" overflow="auto">
        {inventoryOverview.isLoading ? (
          <Center w="full" h="full">
            <CustomSpinner size="xl" />
          </Center>
        ) : (
          <Stack>
            {map(state.categories, (o, index) => (
              <Box key={index + 'inventory-overview-category'}>
                {renderCategory(o)}
              </Box>
            ))}
          </Stack>
        )}
      </Box>
    </Stack>
  );
};
