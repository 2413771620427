import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import {
  Box,
  Divider,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { startCase } from 'lodash';
import { Application } from 'pixi.js';
import { useDispatch, useSelector } from 'react-redux';

import { getProviderAlias } from 'components/DataDisplay';
import {
  FieldValT2,
  FieldValue,
} from 'components/DataDisplay/Text/FieldValueText';
import { NodeType } from 'components/Visualization/PixiGraph/common/NodeType';
import { intoNodeTitle } from 'components/Visualization/PixiGraph/common/utils/nodeInfo';
import { GraphMenu } from 'components/Visualization/PixiGraph/components/GraphMenu';
import { GraphMenuItemTypes } from 'components/Visualization/PixiGraph/components/GraphMenuItem';
import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';
import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import { AGraphRenderer } from 'components/Visualization/PixiGraph/core/Renderer';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { Ec2TrafficSummaryModal } from 'containers/Visibility/SecurityGraphNext/Components/Ec2TrafficSummary';
import { TrafficConfigModal } from 'containers/Visibility/SecurityGraphNext/Components/TrafficConfigModal';
import { TrafficInfoModal } from 'containers/Visibility/SecurityGraphNext/Components/TrafficInfoModal';
import { TrafficOverview } from 'containers/Visibility/SecurityGraphNext/Components/TrafficOverview';
import { useMouseOutElement } from 'containers/Visibility/SecurityGraphNext/hooks/useMouseOutElement';
import { useSecurityGraphContext } from 'containers/Visibility/SecurityGraphNext/hooks/useSecurityGraphContext';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { highlightEdges } from 'containers/Visibility/SecurityGraphNext/utils/highlight_edges';

interface IGraphMenu {
  app: Application;
  graph: AGraph<AGraphNode, AGraphEdge>;
  renderer: AGraphRenderer;
}

export const PostureGraphMenu: FC<IGraphMenu> = props => {
  const { graph, app, renderer } = props;
  const dispatch = useDispatch();
  const { getTabIndex } = useMetadataDrawerTabs();

  const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
  const [showMenu, setShowMenu] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState<any>({
    left: 0,
    top: 0,
    width: 20,
    height: 30,
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipInfo, setTooltipInfo] = useState('');
  const [data, setData] = useState<Record<string, any> | null>(null);
  const trafficInfo = useDisclosure();
  const trafficConfig = useDisclosure();
  const trafficSummary = useDisclosure();

  const { riskContext } = useSelector(selectSharedState);
  const { resourceAlerts, redirectInfo } = useSelector(selectSecurityGraphNext);
  const { onOpenMetadata } = useActiveResourceContext();
  const { bus } = useSecurityGraphContext();
  const { updateActiveResource } = useActiveResourceActions();
  const [showNodeOverview, setShowNodeOverview] = useState<AGraphNode | null>(
    null,
  );

  const [showEdgeOverview, setShowEdgeOverview] = useState<AGraphEdge | null>(
    null,
  );

  useEffect(() => {
    const onScroll = () => {
      setShowTooltip(false);
      setShowMenu(false);
    };
    graph.on('wheel', onScroll);
    return () => {
      graph.off('wheel', onScroll);
    };
  }, [graph]);

  const handleShowOverview = useCallback(
    node => {
      const { data = {} } = node;
      if (data.name === 'Ingress' || data.name === 'Egress') {
        const [parent] = graph.getPreviousNodes(node);
        if (!parent) return;
        if (parent.nodeType === NativeResources.SecurityGroup) {
          setData({
            resourceType: NativeResources.SecurityGroup,
            parent: parent.data,
            ...data,
          });
          trafficConfig.onOpen();
          return;
        }

        if (parent.nodeType === NativeResources.ElasticNetworkInterface) {
          setData({
            resourceType: NativeResources.ElasticNetworkInterface,
            parent: parent.data,
            ...data,
          });
          trafficInfo.onOpen();
          return;
        }
        // console.log('show traffic info', data,);
      } else if (
        data.nodeType === NativeResources.IamRole ||
        data.nodeType === NativeResources.IamUser
      ) {
        const { account, resource_id, uuid, native_name } = data; //graph.activeNodes?.[0]?.data;
        updateActiveResource({
          resourceType: native_name,
          uuid: uuid,
          accountId: account,
          resourceId: resource_id,
        });
        onOpenMetadata(getTabIndex(native_name, redirectInfo?.source_tab));
        setShowMenu(false);
      } else if (NodeType.isResource(node)) {
        const { account, resource_id, uuid, native_name } = data; //graph.activeNodes?.[0]?.data;
        updateActiveResource({
          resourceType: native_name,
          uuid: uuid,
          accountId: account,
          resourceId: resource_id,
        });
        onOpenMetadata(getTabIndex(native_name, redirectInfo?.source_tab));
        setShowMenu(false);
      }
    },
    [
      getTabIndex,
      graph,
      onOpenMetadata,
      redirectInfo?.source_tab,
      trafficConfig,
      trafficInfo,
      updateActiveResource,
    ], //graph.activeNodes,
  );

  useEffect(() => {
    const onMouseDown = entries => {
      const firstEntry = entries[0];
      const nodes = entries.map(c => c.data) ?? [];
      // console.log(nodes);
      if (nodes.length) {
        const node = nodes[0]!;

        const done = bus.emit('node:click', node);
        if (done) return;

        if (node.type === 'Internet Access') {
          console.log('show traffic info', node);
          setData({
            resourceType: NativeResources.Ec2Instance,
            ...node.node.data,
          });
          trafficSummary.onOpen();
          return;
        }

        if (node instanceof AGraphEdge) {
          highlightEdges(firstEntry, renderer, app, graph);
        } else if (
          !node.data.ariksaType ||
          node.data.ariksaType?.auxiliary ||
          NodeType.isEndpoint(node)
        ) {
          setShowMenu(false);
          return;
        } else {
          handleShowOverview(node);
        }
      }
    };

    graph.on('mousedown:element', onMouseDown);
    return () => {
      graph.off('mousedown:element', onMouseDown);
    };
  }, [app, bus, graph, handleShowOverview, renderer, trafficSummary]); //app.stage.x, app.stage.y, renderer.activeGfx

  useEffect(() => {
    const onMouseOver = entries => {
      const elements = entries.map(e => e.data);
      if (!elements.length) {
        setShowTooltip(false);
        return;
      }

      const element = elements[0];

      const entry = entries[0];
      const { id, data } = entry;
      const [type, , severity] = id.split(':');

      if (type === 'severity') {
        setShowTooltip(true);
        setTooltipStyle({
          left: entry.minX + app.stage.x - 15 + 'px',
          top: entry.minY + app.stage.y - 35 + 'px',
          // width: 120 + 'px',
          height: 30,
        });
        setTooltipInfo(
          `${startCase(severity)}: ${data.severities[severity.toUpperCase()]}`,
        );
        return;
      }

      if (type === 'context') {
        setShowTooltip(true);
        setTooltipStyle({
          left: entry.minX + app.stage.x + 'px',
          top: entry.minY + app.stage.y - 34 + 'px',
          height: 30,
        });
        setTooltipInfo(`${element.displayName ?? '-'}`);
      }

      if (type === 'edge-info') {
        setShowEdgeOverview(element);
      }

      if (element instanceof AGraphNode) {
        setShowNodeOverview(element);
      }
    };

    graph.on('mouseover:element', onMouseOver);
    return () => {
      graph.off('mouseover:element', onMouseOver);
    };
  }, [app.stage.x, app.stage.y, graph]);

  useMouseOutElement(graph, entries => {
    const entry = entries[0];
    const { data } = entry;

    if (data instanceof AGraphNode) {
      setShowNodeOverview(null);
    }

    if (data instanceof AGraphEdge) {
      setShowEdgeOverview(null);
    }
  });

  const handleShowAlerts = useCallback(
    e => {
      const node = graph.activeNodes[0];
      if (!node) return;
      if (node.data.severities) {
        node.data.severities = null;
      } else {
        node.data.severities = resourceAlerts.data.severity?.[node.data.uuid];
      }
      renderer.update();
      setShowMenu(false);
    },
    [graph.activeNodes, renderer, resourceAlerts.data.severity],
  );

  const handleShowContexts = useCallback(
    e => {
      const node = graph.activeNodes[0];
      if (!node) return;
      const { data } = node;

      if (riskContext?.data[data.uuid]) {
        dispatch(
          sharedStateActions.getRiskContext({
            q: {
              riskContextRequest: { uuids: ['x'] },
            },
          }),
        );
      } else {
        dispatch(
          sharedStateActions.getRiskContext({
            q: {
              riskContextRequest: { uuids: [data.uuid] },
            },
          }),
        );
      }

      setShowMenu(false);
    },
    [dispatch, graph.activeNodes, riskContext?.data],
  );

  const graphMenuItems = useMemo(
    () => [
      {
        type: GraphMenuItemTypes.overview,
        onClick: handleShowOverview,
      },
      {
        type: GraphMenuItemTypes.alerts,
        onClick: handleShowAlerts,
      },
      {
        type: GraphMenuItemTypes.contexts,
        onClick: handleShowContexts,
      },
    ],
    [handleShowAlerts, handleShowContexts, handleShowOverview],
  );

  const isEncrypted = graph.edges.find(
    n => n.data.identity == '5:c1f00512-7b41-4e16-b271-a1309a7543af:160492',
  );

  const edgeProps = showEdgeOverview?.data?.properties ?? {};

  return (
    <>
      {showMenu && <GraphMenu items={graphMenuItems} styles={menuPosition} />}
      {showTooltip && (
        <Flex
          pos={'absolute'}
          bg={'#2D3748'}
          borderRadius={4}
          color={'#fff'}
          align={'center'}
          fontSize={'xs'}
          px={2}
          {...tooltipStyle}
        >
          {tooltipInfo}
        </Flex>
      )}
      {trafficInfo.isOpen && data && (
        <TrafficInfoModal
          data={data}
          onClose={trafficInfo.onClose}
          isOpen={trafficInfo.isOpen}
        />
      )}
      {trafficConfig.isOpen && data && (
        <TrafficConfigModal
          data={data}
          onClose={trafficConfig.onClose}
          isOpen={trafficConfig.isOpen}
        />
      )}
      {trafficSummary.isOpen && data && (
        <Ec2TrafficSummaryModal
          data={data}
          onClose={trafficSummary.onClose}
          isOpen={trafficSummary.isOpen}
        />
      )}
      {showNodeOverview && (
        <Box
          pos={'absolute'}
          bg={'#2D3748'}
          borderRadius={4}
          color={'#fff'}
          fontSize="sm"
          left={showNodeOverview.x + app.stage.x}
          top={showNodeOverview.y + showNodeOverview.h + app.stage.y + 10}
          w={
            showNodeOverview?.data?.native_name === NativeResources.Ec2Instance
              ? '650px'
              : 340
          }
          px={2}
        >
          {showNodeOverview?.data?.native_name ===
            NativeResources.Ec2Instance && (
            <TrafficOverview
              resourceId={showNodeOverview?.data?.resource_id}
              accountUuid={showNodeOverview?.data?.account}
            />
          )}
          {showNodeOverview?.data?.native_name !==
            NativeResources.Ec2Instance && (
            <>
              <Box color={'orange'} py={2} borderBottom={'1px solid orange'}>
                Overview
              </Box>
              <Stack color={'#fff'} py={2} spacing={1}>
                {/*{(NodeType.isResource(showNodeOverview) ||*/}
                {/*  NodeType.isProcess(showNodeOverview)) && (*/}
                <FieldValT2
                  field={'Name'}
                  value={
                    (intoNodeTitle(showNodeOverview) ||
                      showNodeOverview.data.name ||
                      showNodeOverview.data.resource_id) ??
                    '-'
                  }
                />
                <FieldValT2
                  field={'Type'}
                  value={
                    showNodeOverview.data?.ariksaType?.aliase ??
                    showNodeOverview.data?.ariksaType?.cloud_native_name ??
                    showNodeOverview.data?.native_name ??
                    ''
                  }
                />
                {NodeType.isResource(showNodeOverview) && (
                  <FieldValT2
                    field={'Cloud'}
                    value={getProviderAlias(showNodeOverview?.data?.provider)}
                  />
                )}
                {showNodeOverview.data.resource_id && (
                  <FieldValT2
                    field={'ID'}
                    value={showNodeOverview.data.resource_id ?? ''}
                  />
                )}
              </Stack>
            </>
          )}
        </Box>
      )}

      {showEdgeOverview && (
        <Box
          pos={'absolute'}
          bg={'#2D3748'}
          borderRadius={4}
          color={'#fff'}
          fontSize="sm"
          left={
            (showEdgeOverview.start.x + showEdgeOverview.end.x) / 2 +
            app.stage.x
          }
          top={
            (showEdgeOverview.start.y + showEdgeOverview.end.y) / 2 +
            app.stage.y
          }
          w={340}
          px={2}
        >
          <Box color={'orange'} py={2} borderBottom={'1px solid orange'}>
            Overview
          </Box>
          <Stack py={2} spacing={1}>
            <FieldValue field={'DB Type'} value={edgeProps?.db_type} />
            <FieldValue field={'DB Name'} value={edgeProps?.db_name} />
            <FieldValue
              field={'Encrypted'}
              value={edgeProps?.encrypted ? 'Yes' : 'No'}
            />
            <FieldValue field={'Login'} value={edgeProps?.db_user_name} />

            <FieldValue
              field={'Container ID'}
              value={edgeProps?.container_id}
            />
            <Stack pt={2}>
              <Text>Stats over 24 hours</Text>
              <Divider />

              <FieldValue
                field={'Bytes send'}
                value={(edgeProps['Total bytes sent'] ?? '-') + ' bytes'}
              />
              <FieldValue
                field={'Bytes received'}
                value={(edgeProps['Total bytes received'] ?? '-') + ' bytes'}
              />
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};
