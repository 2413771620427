import React from 'react';

import { Box, Stack, HStack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { H2 } from 'components/DataDisplay';
import { CustomerJourneyBulbIcon } from 'components/Icons';

interface Props {
  onClose: () => void;
}

export const AboutAriksaOutpostForVulnerability = (props: Props) => {
  const styles = { pl: 2, color: customTheme.colors.gray['300'] };
  return (
    <Stack spacing={6} fontSize="md">
      <HStack>
        <Box boxSize={10}>
          <CustomerJourneyBulbIcon color="orange" />
        </Box>
        <H2>Deploy Outpost for Vulnerability</H2>
      </HStack>
      <Box {...styles}>
        For efficiency and scale, Ariksa can perform agentless scan of virtual
        machines, containers and serverless functions from a central account,
        which can be a parent or child account.
      </Box>
      <Box {...styles}>
        If an account is not enabled for central scanning, all vulnerability
        assessment is restricted to only the account, region and zone (if
        applicable) where Outpost is deployed.
      </Box>
      <Box {...styles}>
        If an account is enabled for central scanning, we will automatically
        scan resources in that account, in the same region and zone (if
        applicable) as the Outpost.
      </Box>
    </Stack>
  );
};
