import React, { FC } from 'react';

import { Box, Stack, Image } from '@chakra-ui/react';
import channelDetails from 'images/slack/channel_details.png';
import channelOptions from 'images/slack/channelOptions.png';

import { HelpHeader } from 'components/DataDisplay/Utils/HelpHeader';

export const GetChannelID: FC = () => {
  return (
    <Stack h="full">
      <HelpHeader>Get Channel ID</HelpHeader>
      <Stack px={4} spacing={6}>
        <Box>
          Log in to your workspace in Slack via a web browser. Right click on
          the channel name and click on "View channel details". Copy channel ID
          at bottom.
        </Box>
        <Image px={32} src={channelOptions} alt="Channel options" />
        <Image px={28} src={channelDetails} alt="Channel details" />
      </Stack>
    </Stack>
  );
};
